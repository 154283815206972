import {Platform} from "react-native";
import RegisterMobile from "../Components/Identification/RegisterMobile";
import RegisterWeb from "../../Web/Component/Identification Web/RegisterWeb";

const Register = () => {
    return(
        Platform.OS === 'web' ?
            <RegisterWeb />
            :
            <RegisterMobile />
    )
}

export default Register;
