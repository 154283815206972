import {Pressable, StyleSheet, Text, View} from "react-native";
import {Hoverable} from "react-native-web-hover";
import {useEffect, useState} from "react";
import ModalLastEventsDetailsWeb from "./Modals/ModalLastEventsDetailsWeb";
import {COLORS} from "../../../constants/colors";
import moment from "moment";
import {assertBoolean} from "@babel/core/lib/config/validation/option-assertions";
import ModalInvitePerson from "./Modals/ModalInvitePerson";

const TabLastEventUserWeb = ( { invitation, setInvitation, userData, token } ) => {

    const d = new Date();
    const date = (d.getMonth()+1)+'/'+d.getDate()+'/'+d.getFullYear();
    const [filterText, setFilterText] = useState('');
    const [sortOrder, setSortOrder] = useState({ column: 'name', order: 'asc' });
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalInvite, setIsOpenModalInvite] = useState(false);
    const [getId, setId] = useState('');
    const [data, setData] = useState(userData.createdEvents);
    const [eventDate, setEventDate] = useState();
    const [invite, setInvite] = useState(assertBoolean);


    const getDateEvent = (date, id) => {
        setEventDate(date)
        setId(id)
        setIsOpenModalInvite(true)
    }


    const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.startDate.toLowerCase().includes(filterText.toLowerCase()) ||
        item.boat.name.includes(filterText)
        // item.present.toLowerCase().includes(filterText.toLowerCase())
    );


    const getItemId = (id) => {
        setId(id)
        setIsOpenModal(true)
    }

    const handleSort = (column) => {
        const sortedData = data.slice().sort((a, b) => {
            if (sortOrder.order === 'asc') {
                return a[column].localeCompare(b[column]);
            } else {
                return b[column].localeCompare(a[column]);
            }
        });
        data(sortedData);
        setSortOrder({ column: column, order: sortOrder.order === 'asc' ? 'desc' : 'asc' });
    };
    return(
        <View style={styles.container}>
            <View style={styles.headerTab}>
                <View style={styles.headerTabContent}>
                    <Text style={{fontSize: 22, color: COLORS.opiomMainColor}}>
                        Liste des évènements passés
                    </Text>
                    <Pressable style={{width: 200, height: 40, backgroundColor: COLORS.opiomMainColor, justifyContent: 'center'}} onPress={() => setInvitation(true)}>
                        <Text style={{color: 'white', textAlign: 'center', fontWeight: 'bold'}}>
                            Mes invitations
                        </Text>
                    </Pressable>
                </View>
            </View>

            <View  style={{alignItems: 'center', width: '100%'}}>
                <View style={[styles.row, {backgroundColor: '#F3F3F3'}]}>
                    <Pressable style={styles.cell} onPress={() => handleSort('dateEvent')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'dateEvent' ? (sortOrder.order === 'asc' ? 'Date de L\'évènement ▲' : 'Date de L\'évènement ▼') : 'Date de L\'évènement'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('nameEvent')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'nameEvent' ? (sortOrder.order === 'asc' ? 'Nom de l\'évènement ▲' : 'Nom de l\'évènement ▼') : 'Nom de l\'évènement'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('nameOrg')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'nameOrg' ? (sortOrder.order === 'asc' ? 'Nom du bateau ▲' : 'Nom du bateau ▼') : 'Nom du bateau'}</Text>
                    </Pressable>
                </View>
                {
                    filteredData.map((item, key) => (
                        <Hoverable key={key} style={[key %2 ==0 ? styles.white : styles.backgroundRow, {width: '95%'}]}>
                            {({ hovered }) => (
                                <>
                                    <View style={{width: '100%', flexDirection: 'row', paddingLeft: 25, paddingVertical: 5}}>
                                        <View style={[styles.buttonTab, {display: hovered ? 'flex' : 'none'}]}>
                                            <Pressable style={styles.buttonG}>
                                                <Text style={{fontSize: 9, color: 'white'}}>Supprimer</Text>
                                            </Pressable>
                                            <Pressable style={styles.buttonD} onPress={() => getItemId(item.id)}>
                                                <Text style={{color: 'white', fontSize: 9}}>Consulter</Text>
                                            </Pressable>
                                            {
                                                moment(item.startDate).format('l') >= date &&
                                                <Pressable  style={[styles.buttonD, {backgroundColor: 'green'}]} onPress={() => getDateEvent(item.startDate, item.id)}>
                                                    <Text style={{color: 'white', fontSize: 9}}>Inviter</Text>
                                                </Pressable>
                                            }
                                        </View>
                                        <Text style={[styles.cell, hovered && styles.rowHovered]}>{moment(item.startDate).locale('fr').format('L')}</Text>
                                        <Text style={[styles.cell, hovered && styles.rowHovered]}>{item.name}</Text>
                                        <Text style={[styles.cell, hovered && styles.rowHovered]}>{item.boat.name}</Text>
                                        {/*<Text style={[styles.cell, hovered && styles.rowHovered]}>{item.present}</Text>*/}
                                    </View>
                                    {isOpenModal && getId === item.id && <ModalLastEventsDetailsWeb data={data[key]} setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal}/>}
                                    {isOpenModalInvite && eventDate === item.startDate && getId === item.id  && <ModalInvitePerson data={data[key]} token={token} setIsOpenModalInvite={setIsOpenModalInvite}/>}
                                </>
                            )}
                        </Hoverable>
                    ))
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: '65%',
        flexDirection: "column",
        marginLeft: '10%'
    },
    headerTab: {
        width: '100%',
        marginBottom: 50
    },
    headerTabContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '85%',
        marginLeft: 30,
        alignItems: 'center'
    },
    buttonHeaderTab: {
        marginHorizontal: 10,
        backgroundColor: '#EEEEEE',
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    textButton: {
        fontSize: 13,
        color: '#0F1C2A',
        fontWeight: 'bold'
    },
    white: {
        backgroundColor: 'white'
    },
    backgroundRow: {
        backgroundColor: '#F3F3F3',
    },
    cell: {
        flex: 1,
        textAlign: 'left',
        marginLeft: 40
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingLeft: 25,
        width: '95%',
    },
    nameCol: {
        fontSize: 13,
        fontWeight: 'bold'
    },
    rowHovered: {
        filter: 'blur(1.5px)',
    },
    buttonTab: {
        flexDirection: 'row',
        position: 'absolute',
        left: '22%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999
    },
    buttonG: {
        backgroundColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    buttonD: {
        backgroundColor: '#0B1B2B',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginLeft: 10
    },
    hoveredButtonG: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    hoveredButtonD: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
})

export default TabLastEventUserWeb;
