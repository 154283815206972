import {Pressable, StyleSheet, Text, View} from "react-native";
import {Link} from "react-router-dom";
import {COLORS} from "../../constants/colors";

const FinalRecap = ( { setState } ) => {
    return (
        <View>
            <View style={{alignItems: 'center', width: 800}}>
                <View style={styles.headerSuivi}>
                    <View style={{alignItems: 'center', marginTop: 40}}>
                        <Text style={{fontSize: 20, marginBottom: 30}}>
                            Votre demande a bien été prise en compte
                        </Text>
                        <Text style={{marginBottom: 5, textAlign: 'center'}}>Vous pouvez désormais gérer cette évnènement et y ajouter des </Text>
                        <Text style={{textAlign: 'center'}}>prestations<Text style={{fontWeight: 'bold'}}>traiteur, animation et bar</Text> dans "vos évènements".</Text>
                        <View style={{flexDirection: 'row', marginTop: 40, alignItems: 'center'}}>
                            <Pressable style={{paddingVertical: 5, paddingHorizontal: 20, borderRadius: 25, backgroundColor: COLORS.gris, marginHorizontal: 10}}>
                                <Link to='/'>
                                    <Text>Revenir à mon espace</Text>
                                </Link>
                            </Pressable>
                            <Pressable style={{paddingVertical: 5, paddingHorizontal: 20, borderRadius: 25, backgroundColor: '#0B1B2B', marginHorizontal: 10}} onPress={() => setState(6)} >
                                <Text style={{color: 'white', fontWeight: 'bold'}}>Invitez des personnes</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    h1: {
        textTransform: 'uppercase',
        fontSize: 20,
        opacity: 0.3,
        marginVertical: 5,
        marginRight: 10
    },
})

export default FinalRecap;
