import {Pressable, Text, TextInput, View, StyleSheet, Dimensions} from "react-native";
import {useState} from "react";
import {api} from "../../config/axios";
import * as React from "react";

const invitPerson = (  { token, idEvent } ) => {
    const [link, setLink] = useState('')

    const handleSubmit = () => {
        if(link){
            api.post(`events/${idEvent}?link=${link}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then()
                .catch((error) => console.log(error))
        }
    }

    return(
            <View style={{width: Dimensions.get("window").width > 650 ? '70%' : '90%', marginTop: 60, alignItems: 'center', alignSelf: 'center'}}>
                <Text style={{fontSize: 16}}>Veuillez insérer le lien google sheet contenant la liste des personnes à inviter dans le champ ci-dessous</Text>
                <TextInput style={styles.input} onChangeText={(newVal) => setLink(newVal)} />
                <Text style={{fontSize: 16, marginBottom: 10}}>
                    Veuillez suivre les instructions notées sur le document : <a href='https://docs.google.com/spreadsheets/d/1JUYmesq06ptnVdzI-LmZFpCYiNppAAySQZyKTW7pIY4/edit#gid=0' target='_blank'>Ici</a>
                </Text>
                <Text style={{fontSize: 16}}>
                    Avant de valider, veillez à ce que les noms, prénoms et adresses mails sont bien notés dans leur colonne respective.{'\n'}
                    Si cela n'est pas respecté, les personnes concernées risqueront de ne pas recevoir d'invitation.
                </Text>
                <Pressable style={styles.button} onPress={handleSubmit}>
                    <Text style={{color: 'white', fontSize: 15}}>
                        Valider et envoyer
                    </Text>
                </Pressable>
            </View>
    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#0F1C2B',
        marginTop: 20,
        marginBottom: 30,
        width: Dimensions.get("window").width > 650 ? 500 : '100%',
        height: 25
    },
    button: {
        width: 160,
        height: 50,
        backgroundColor: '#0F1C2B',
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default invitPerson;
