import ProfilMobile from "./Profil/ProfilMobile";
import React, { useState } from "react";
import { View, StyleSheet, Text,Pressable } from "react-native";
import {COLORS} from "../../constants/colors";

export default function Menu({ navigation }) {

  return (
    <View style={styles.container}>
        <View style={{alignItems: 'center'}}>
          <Pressable>
            <Text style={[styles.menuItems, {fontWeight: 'bold'}]}>Accueil</Text>
          </Pressable>
          <Pressable>
            <Text style={styles.menuItems}>Nos prestations</Text>
          </Pressable>
          <Pressable>
            <Text style={styles.menuItems}>Tarifs</Text>
          </Pressable>
          <Pressable>
            <Text style={styles.menuItems}>Notre histoire</Text>
          </Pressable>
          <Pressable>
            <Text style={[styles.menuItems, {fontWeight: 'bold', marginBottom: 30}]}>Thomas</Text>
          </Pressable>
        </View>
    </View>
);
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.opiomMainColor,
    shadowColor: '#00000029',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 2,
    zIndex: 1
  },
  menuItems: {
    fontSize: 18,
    color: '#ffff',
    marginVertical: 15
  }
});
