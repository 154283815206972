import * as React from 'react';
import {Text, View, StyleSheet, TextInput, Pressable, Image, Dimensions} from "react-native";
import Header from './Header';
import {useState} from "react";
import {api} from "../../../config/axios";
import * as SecureStore from "expo-secure-store";
function LoginMobile( {navigation} ) {
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [getToken, setToken] = useState(null)
    const [show, setShow] = useState(true)

    const userLogin = (e) => {
        let userData = {
            email: mail,
            password: password,
        }
        api.post(`auth/login`, userData)
            .then((res) =>{
                SecureStore.setItemAsync('jwtToken', res.data.access_token)
                    .then(r =>navigation.navigate('Profil'))
            })
            .catch((error) => console.log(error))


        // console.log(getToken)
        // const storeValue = async () => {
        //     try {
        //         await SecureStore.setItemAsync('jwtToken', getToken)
        //         console.log('token bien stocké')
        //         const value = await SecureStore.getItemAsync('jwtToken');
        //         console.log(value)
        //     } catch (error) {
        //         console.log('Erreur lors du stockage de la valeur:', error);
        //     }
        // };
        // storeValue()
    }


    return (
        <View
            style={{
                backgroundColor: '#EDEDED',
                width: '100%',
                height: Dimensions.get('window').height,
                alignItems: 'center',
            }}>

            <Header />

            <View style={styles.formContainer}>
                <Text style={styles.title}>me connecter</Text>
                <View>
                    <Text style={styles.label}>Email</Text>
                    <TextInput style={styles.input} onChangeText={(newValue) => setMail(newValue)} />
                </View>

                <View style={styles.labelInput}>
                    <Text style={styles.label}>Password</Text>
                    <TextInput style={styles.input} secureTextEntry={show} autoCorrect={false} onChangeText={(newValue) => setPassword(newValue)} placeholder='Doit contenir 6 caractères min.' />
                    <Pressable onPress={() => setShow(!show)}>
                        <Image style={styles.hideButton} source={require('../../../../assets/images/Show-Hide.png')}/>
                    </Pressable>
                </View>

                <View style={{marginTop: 20, flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={{fontWeight: 'bold', marginLeft: 15, fontSize: 10}}>
                        Se souvenir pour la prochaine fois
                    </Text>
                </View>

                <View style={styles.button}>
                    <Pressable style={({ pressed }) => [
                        {
                            backgroundColor: pressed ? '#63A645' : '#D9D9DC',
                        },
                    ]} onPress={(e) => userLogin(e)}>
                        <Text style={{padding: 15, fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>Se connecter</Text>
                    </Pressable>
                </View>
                {/*<Pressable onPress={navigation.navigate('CreateEvent')}>*/}
                {/*    <Text style={{textAlign: 'center', marginTop: 30}}>*/}
                {/*        Créer un évènement*/}
                {/*    </Text>*/}
                {/*</Pressable>*/}
                <View>
                    <Text style={{textAlign: 'center', marginTop: 30, fontWeight: 'bold', textDecorationLine: 'none'}}>Vous ne possédez pas encore de compte ? </Text>
                </View>
                <Pressable onPress={() => navigation.navigate('Register')}>
                    <Text style={{textAlign: 'center', textDecorationLine: 'underline'}}>Inscrivez-vous ici !</Text>
                </Pressable>
            </View>
        </View>
    );
}

// @ts-ignore
const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 25,
        textTransform: 'uppercase',
    },
    label: {
        margin: 10,
        marginLeft: 0,
    },
    labelInput: {
        marginTop: 20,
    },
    buttonLogin: {
        color: 'black',
        fontFamily: 'montserrat',
    },
    button: {
        marginTop: 20,
        backgroundColor: '#D9D9DC',
        borderRadius: 4,
        color: 'white',
    },
    formContainer: {
        width: '80%',
        marginTop: 40,
    },
    container: {
        padding: 8,
        alignItems: 'center',
        backgroundColor: '#EDEDED',
    },
    input: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderColor: '#BFBFC8',
        borderWidth: 1,
        padding: 10,
        borderRadius: 10,
    },
    login: {
        textAlign: 'center',
        marginTop: 50,
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
    },
    hideButton: {
        position: 'absolute',
        right: 10,
        bottom: 15
    },
});

export default LoginMobile;
