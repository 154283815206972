import {Platform} from "react-native";
import {useState} from "react";
import ChoiceHoraireWeb from "../../Web/Component/CreateEventComponents/ChoiceHoraireWeb";
import ChoiceHoraireMobile from "../../Mobile/Components/CreateEventComponents/ChoiceHoraireMobile";


const ChoiceHoraire = ( { isSelectedTime, setIsSelectedTime, setHoraire, setSelectDay, selectDay, setDuration } ) => {
    const [data, setData] = useState([
        {id: 1, heureDebut: 6, duree: 5},
        {id: 2, heureDebut: 12, duree: 5},
        {id: 3, heureDebut: 18, duree: 7},
    ]);

    return (
        Platform.OS === 'web' ?
            <ChoiceHoraireWeb
                data={data}
                isSelectedTime={isSelectedTime}
                setIsSelectedTime={setIsSelectedTime}
                setHoraire={setHoraire}
                setSelectDay={setSelectDay}
                selectDay={selectDay}
                setDuration={setDuration}
            />
            :
            <ChoiceHoraireMobile
                data={data}
                isSelectedTime={isSelectedTime}
                setIsSelectedTime={setIsSelectedTime}
                setHoraire={setHoraire}
                setSelectDay={setSelectDay}
                selectDay={selectDay}
                setDuration={setDuration}
            />
    )
}

export default ChoiceHoraire;
