
import ProfilMenu from "../Components/Profil/ProfilMenu";
import Profil from "../../Pages/Profil";
import ListEvent from "../../Pages/ListEvent";
import CreateEvent from "../../Pages/CreateEventPages/CreateEvent";
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import ProfilForm from "../Components/Profil/ProfilForm";
import CreditAccountMobile from "../Components/Profil/CreditAccountMobile";
import CreditAccount from "../../Pages/CreditAccount";

const Stack = createNativeStackNavigator();
const AppStack = () => {
  return(
      <NavigationContainer>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
              {/*<Stack.Screen name="Home" component={Home} />*/}
              <Stack.Screen name="Profil" component={Profil} />
              <Stack.Screen name="ProfilMenu" component={ProfilMenu} />
              <Stack.Screen name="ProfilForm" component={ProfilForm} />
              <Stack.Screen name="ListEvent" component={ListEvent} />
              <Stack.Screen name="CreateEvent" component={CreateEvent}/>
              <Stack.Screen name="CreditAccount" component={CreditAccount}/>
              {/*<Stack.Screen name="ListUser" component={() => <ListUser />} />*/}
          </Stack.Navigator>
      </NavigationContainer>
  )
}

export default AppStack;