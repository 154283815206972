import {Platform} from "react-native";
import ProfilMobile from '../Mobile/Components/Profil/ProfilMobile'
import ProfilWeb from "../Web/Component/Profil/ProfilWeb";
import {useEffect, useState} from "react";
import {api} from "../config/axios";
import Cookies from "universal-cookie/es6";
import UserProfil from "./UserProfil";
import * as SecureStore from "expo-secure-store";
import jwtDecode from "jwt-decode";

const Profil = ( { role } ) => {

    const [token, setToken] = useState(null)
    const [data, setData] = useState({})
    const [idUser, setIdUser] = useState()

    useEffect(() => {
        if(Platform.OS !== 'web'){
            const getValue = async () => { // Récupérer le token stocké dans le secureStore
                try {
                    setToken(await SecureStore.getItemAsync('jwtToken'));
                    if(token){
                        const jwtDecode = require('jwt-decode')
                        const decodedToken = jwtDecode(token)
                        setIdUser(decodedToken.id)
                    }
                } catch (error) {
                    console.log('Erreur lors de la récupération de la valeur:', error);
                }
            };
            getValue()
        }else {
            const getRoleFromToken = async () => {
                try {
                    const cookies = new Cookies();
                    const value =await cookies.get('jwtToken')
                    setToken(value)
                    if(token) {
                        const decodedToken = jwtDecode(token);
                        setIdUser(decodedToken.id)
                    }
                } catch (error) {
                    console.log('Une erreur s\'est produite lors du décodage du token :', error);
                    return null;
                }
            };
            getRoleFromToken();
        }
    const getUser = () => {
        if (idUser && token) {
            api.get(`users/${idUser}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => setData(res.data))
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    getUser()
    }, [idUser, token])

    return(
        Platform.OS === 'web' ?
            <ProfilWeb
                role={role}
                data={data}
                token={token}
                idUser={idUser}
            />
            :
            <UserProfil
                role={role}
                data={data}
                token={token}
            />
    )
}

export default Profil;
