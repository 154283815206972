import {Platform} from "react-native";

import {useEffect, useState} from "react";
import Cookies from "universal-cookie/es6";
import {api} from "../../config/axios";
import ChoiceOrgMobile from "../../Mobile/Components/CreateEventComponents/ChoiceOrgMobile";
import ChoiceOrgWeb from "../../Web/Component/CreateEventComponents/ChoiceOrgWeb";
import * as SecureStore from "expo-secure-store";

const ChoiceOrg = ({ idUser, setOrgId, setIsPublic, isEventSelected, setIsEventSelected }) => {

    const [data, setData] = useState();
    const [token, setToken] = useState('')

    useEffect(() => {
        if(Platform.OS === 'web'){
            const getTokenWeb = () => {
                const cookies = new Cookies;
                setToken(cookies.get('jwtToken'))
                // console.log(token)
            }
            getTokenWeb()
        }else{
            const getValue = async () => { // Récupérer le token stocké dans le secureStore
                try {
                     setToken(await SecureStore.getItemAsync('jwtToken'));
                } catch (error) {
                    console.log('Erreur lors de la récupération de la valeur:', error);
                }
            };
            getValue();
        }
        const getUser = () => {
            if (token && idUser) {
                api.get(`users/${idUser}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then((res) => {
                        setData(res.data.organizations);
                        setOrgId(data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
        getUser()
    }, [token, idUser])

    return(
        Platform.OS === 'web' ?
            <>
                <ChoiceOrgWeb
                    idUser={idUser}
                    setOrgId={setOrgId}
                    data={data}
                    token={token}
                    setIsPublic={setIsPublic}
                    isEventSelected={isEventSelected}
                    setIsEventSelected={setIsEventSelected}
                />
            </>
            :
            <>
                <ChoiceOrgMobile
                    idUser={idUser}
                    setOrgId={setOrgId}
                    data={data}
                />

            </>
    )
}

export default ChoiceOrg
