import {Pressable, SafeAreaView, Text, View, StyleSheet, TextInput} from "react-native";
import {useEffect, useState} from "react";
import * as Font from "expo-font";
import AppLoading from 'expo-app-loading'
import {COLORS} from "../../constants/colors";
import {useNavigation} from "@react-navigation/native";



const TabListEvent = ({ tableData, setTableData }) => {
    const styles = StyleSheet.create({
        mobileContainer: {
            width: '80%',
            alignSelf: 'center',
            height: 200,
            marginTop: 30
        },
        buttonHeaderTabMobile: {
            paddingVertical: 5,
            flexDirection: 'row',
            justifyContent: "space-between",
            marginVertical: 30
        },
        buttonMobile: {
            width: 40,
            height: 40,
            alignItems: 'center',
            backgroundColor: '#EEEEEE',
            justifyContent: 'center'
        },
        nameEventContainer: {
            paddingVertical: 15,
            paddingHorizontal: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        nameEventText: {
            fontSize: 15,
        },
        white: {
            backgroundColor: 'white'
        },
        backgroundRow: {
            backgroundColor: '#F3F3F3',
        },
        buttonHeaderTab: {
            backgroundColor: '#EEEEEE',
            paddingVertical: 15,
            paddingHorizontal: 15,
        },
        triContainer: {
            position: 'absolute',
            width: '95%',
            backgroundColor: '#F9F9F9',
            top: '65%',
            right: 0,
            alignItems: 'center',
            shadowOffset: { width: 0, height: 3 },
            shadowColor: '#000000',
            shadowOpacity: 0.16,
            shadowRadius: 6
        },
        triContent: {
            width: '70%',
            marginVertical: 30,
        },
        triPressable: {
            paddingVertical: 10
        }
    })

    const [isOpen, setIsOpen] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [searchButton, setSearchButton] = useState(false);
    const [triButton, setTriButton] = useState(false);
    const [sortOrder, setSortOrder] = useState({ column: 'name', order: 'asc' });
    const [selectId, setSelectId] = useState(null);
    const navigation = useNavigation();

    const filteredData = tableData.filter((item) =>
        item.id.includes(filterText) ||
        item.nameEvent.toLowerCase().includes(filterText.toLowerCase()) ||
        item.nameOrg.toLowerCase().includes(filterText.toLowerCase()) ||
        item.dateEvent.includes(filterText) ||
        item.lastUpdate.includes(filterText) ||
        item.typeOrd.toLowerCase().includes(filterText.toLowerCase())
    );

    const openEvent = (id) => {
        setIsOpen(id)
        if(isOpen === id) {
            setIsOpen(null)
        }else {
            setIsOpen(id)
        }
    }

    const handleSort = (column) => {
        const sortedData = tableData.slice().sort((a, b) => {
            if (sortOrder.order === 'asc') {
                return a[column].localeCompare(b[column]);
            }else if(sortOrder.order === 'desc') {
                return b[column].localeCompare(a[column]);
            }
        });
        setTableData(sortedData);
        setSortOrder({ column: column, order: sortOrder.order === 'asc' ? 'desc' : 'asc' });
        setTriButton(false);

    };

    const handleEvent = (id) => {
        setSelectId(id)
        if(selectId === id) {
            setSelectId(null)
        }else {
            setSelectId(id)
        }
    }


    const handleSearch = () => {
        setSearchButton(!searchButton)
    }

    const openTri = () => {
        setTriButton(!triButton)
    }
    return(
        <SafeAreaView style={{backgroundColor: 'white'}}>
            <View style={{minHeight: '100%', backgroundColor: 'white'}}>
                <View style={styles.mobileContainer}>
                    <Text style={{fontSize: 30, color: COLORS.opiomMainColor, fontFamily: 'DMSerif-Regular'}}>
                        Liste des évènements
                    </Text>
                    {
                        !searchButton ? (
                            <View style={styles.buttonHeaderTabMobile}>
                                <Pressable style={[styles.buttonMobile, {backgroundColor: COLORS.opiomMainColor, width: 150}]} onPress={navigation.navigate('CreateEvent')}>
                                    <Text style={[styles.textButton ,{color: 'white', fontSize: 18}]}>Créer</Text>
                                </Pressable>
                                <Pressable style={styles.buttonMobile} onPress={openTri}>
                                    <Text style={styles.textButton}>V</Text>
                                </Pressable>
                                <Pressable style={styles.buttonMobile}>
                                    <Text style={styles.textButton}>F</Text>
                                </Pressable>
                                <Pressable style={styles.buttonMobile} onPress={handleSearch}>
                                    <Text style={styles.textButton}>R</Text>
                                </Pressable>
                            </View>
                        ) : (
                            <View style={[styles.buttonHeaderTabMobile, {width: '100%'}]}>
                                <TextInput value={filterText} onChangeText={setFilterText} style={[styles.buttonHeaderTab, {color: COLORS.opiomMainColor, fontWeight: '400', width: '100%', position: 'relative'}]} placeholder='Rechercher' />
                                <Text style={{position: 'absolute', right: 10, top: '40%'}} onPress={() => setSearchButton(false)}>➖</Text>
                            </View>
                        )
                    }



                    {
                        filteredData?.map((item, key) => (
                            <View key={item.id}>
                                <Pressable key={key} style={[styles.nameEventContainer, key %2 == 0 ?  styles.backgroundRow: styles.white]} onPress={() => openEvent(item.id)}>
                                    <Text style={[styles.nameEventText, {paddingLeft: 20}, isOpen === item.id && {fontWeight: 'bold'}]}>{item.nameEvent}</Text>
                                    <Text style={styles.nameEventText}>+</Text>
                                </Pressable>
                                {
                                    isOpen === item.id &&
                                    <View style={key %2 == 0 ?  styles.backgroundRow: styles.white}>
                                        <View style={{marginVertical: 10}}>
                                            <Text style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Nom de l'organisation: </Text>
                                            <Text style={{textAlign: 'center'}}>{item.nameOrg}</Text>
                                        </View>
                                        <View style={{marginVertical: 10}}>
                                            <Text style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Date de l'évènement: </Text>
                                            <Text style={{textAlign: 'center'}}>{item.dateEvent}</Text>
                                        </View>
                                        <View style={{marginVertical: 10}}>
                                            <Text style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>Présent: </Text>
                                            <Text style={{textAlign: 'center'}}>Oui</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        ))
                    }
                    {
                        triButton ? (
                            <View style={styles.triContainer}>
                                <View style={styles.triContent}>
                                    <Pressable style={styles.triPressable} onPress={() => handleEvent(1)}>
                                        <Text style={selectId === 1 ? {fontWeight: 'bold'} : selectId  ? {opacity: 0.3} : {opacity: 1}}>Ordre croissant</Text>
                                    </Pressable>
                                    <Pressable style={styles.triPressable} onPress={() => handleEvent(2)}>
                                        <Text style={selectId === 2 ? {fontWeight: 'bold'} : selectId  ? {opacity: 0.3} : {opacity: 1}}>Ordre décroissant</Text>
                                    </Pressable>
                                    <Pressable style={styles.triPressable} onPress={() => handleEvent(3)}>
                                        <Text style={selectId === 3 ? {fontWeight: 'bold'} : selectId  ? {opacity: 0.3} : {opacity: 1}}>Ordre alphabétique</Text>
                                    </Pressable>
                                    <Pressable style={styles.triPressable} onPress={() => handleEvent(4)}>
                                        <Text style={selectId === 4 ? {fontWeight: 'bold'} : selectId  ? {opacity: 0.3} : {opacity: 1}}>Ordre alphabétique inverse</Text>
                                    </Pressable>
                                    <Pressable style={[styles.triPressable, {marginTop: 10, paddingVertical: 15}, selectId ? {backgroundColor: '#333333'} : {backgroundColor: '#DDDDDD'}]} onPress={selectId === 1 || selectId == 2 ? () => handleSort('dateEvent') : selectId === 3 || selectId == 4 ? () => handleSort('nameEvent') : ''}>
                                        <Text style={{textAlign: 'center', color: 'white', fontWeight: 'bold'}}>Appliquer</Text>
                                    </Pressable>
                                </View>
                            </View>
                        ) : (
                            <></>
                        )
                    }
                </View>
            </View>
        </SafeAreaView>
    )
}

export default TabListEvent;
