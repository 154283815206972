import React, { useState } from 'react';
import {View, StyleSheet, Platform, Dimensions} from 'react-native';
import { Calendar } from 'react-native-calendars';
import {LocaleConfig} from "react-native-calendars/src/index";

LocaleConfig.locales['fr'] = {
    monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ],
    monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    today: "Aujourd'hui"
};

LocaleConfig.defaultLocale = 'fr';
const Calendars = ( { setSelectDay, selectDay } ) => {

    const markedDates = {
        [selectDay]: {selected: true, disableTouchEvent: true},
        '2023-05-01': { disabled: true }, // Jour désactivé
        '2023-05-05': { textColor: 'red' }, // Jour disponible avec couleur de texte rouge
        '2023-05-10': { textColor: 'blue', selected: true, selectedColor: 'blue' }, // Jour disponible avec couleur de texte bleue et couleur de fond verte pour la date sélectionnée
        '2023-05-15': { textColor: 'purple' }, // Jour disponible avec couleur de texte violette
    };

    return (
        <View style={styles.container}>
            <Calendar
                style={{width: Dimensions.get('window').width > 650 ? 500 : 300}}
                theme={{
                    calendarBackground: '#E6E8E9',
                    textSectionTitleColor: '#0D1B2B',
                    selectedDayBackgroundColor: '#0D1B2B',
                    selectedDayTextColor: '#0D1B2B',
                    todayTextColor: '#00adf5',
                    dayTextColor: '#C6C6C6',
                }}
                onDayPress={(date) => setSelectDay(date.dateString)}
                markingType="period"
                markedDates={markedDates}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    }
})

export default Calendars;
