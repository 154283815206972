import React from 'react';
import {View, Image, Text, StyleSheet, Platform} from 'react-native';
import {COLORS} from "../../constants/colors";

const Footer = () => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.item}>
          <Image
              source={require('../../../assets/images/logo-footer.png')}
              resizeMode='cover'
              style={styles.logo}
          />
        </View>
        <View style={styles.item2}>
          <Text style={styles.text}>Actualités</Text>
          <Text style={styles.text}>Contactez nous</Text>
          <Text style={styles.text}>Mentions légales</Text>
          <Text style={styles.text}>Politiques de confidentialité</Text>
          <Text style={styles.text}>Agence Backstages 2023</Text>
        </View>
      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.opiomMainColor,
    width: '100%',
    justifyContent: 'center',
    alignItems: "center",
    // maxHeight: '100px',
    // marginTop: '100px',
    position: 'absolute',
    bottom: 0
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '90%',
    alignSelf: 'center'
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item2: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    width: '80%'
  },
  logo: {
    aspectRatio: 1/2,
    width: 200,
    height: 100,
  },
  text: {
    fontSize: 20,
    // fontWeight: '600',
    marginHorizontal: 20,
    color: "white"
  },
});

export default Footer;