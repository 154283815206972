import {useState} from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import AllModals from "./Modals/AllModals";
import {COLORS} from "../../../constants/colors";

const  InvitationWeb = ({ setInvitation } ) => {
    // const [tableData, setTableData] = useState([
    //     {id: 1, date: '20/06/2023', eventName: 'Anniversaire', orgName: 'Rest-e', present: 'RSVP', heure: '19h-23h', bateau: 'bateau 2'},
    //     {id: 2, date: '01/07/2023', eventName: 'Pot de départ', orgName: 'Backstages', present: 'RSVP', heure: '12h-17h', bateau: 'bateau 4', tarif: 35}
    // ]);
    const [filterText, setFilterText] = useState('');
    const [sortOrder, setSortOrder] = useState({ column: 'name', order: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [modal, setModal] = useState(0)
    const [getId, setId] = useState()

    // const filteredData = tableData.filter((item) =>
    //     item.date.toLowerCase().includes(filterText.toLowerCase()) ||
    //     item.productConsumed.toLowerCase().includes(filterText.toLowerCase()) ||
    //     item.price.toString().includes(filterText)
    // );


    // const handleSort = (column) => {
    //     const sortedData = tableData.slice().sort((a, b) => {
    //         if (sortOrder.order === 'asc') {
    //             return a[column].localeCompare(b[column]);
    //         } else {
    //             return b[column].localeCompare(a[column]);
    //         }
    //     });
    //     setTableData(sortedData);
    //     setSortOrder({ column: column, order: sortOrder.order === 'asc' ? 'desc' : 'asc' });
    // };

    const getItemId = (id) => {
        setId(id)
        setModal(1)
    }


    return(
        <View style={styles.container}>
            <View style={styles.headerTab}>
                <View style={styles.headerTabContent}>
                    <Text style={{fontSize: 22, color: COLORS.opiomMainColor}}>
                        Mes invitations
                    </Text>
                    <Pressable style={{width: 300, height: 40, backgroundColor: COLORS.opiomMainColor, justifyContent: 'center'}} onPress={() => setInvitation(false)}>
                        <Text style={{color: 'white', textAlign: 'center', fontWeight: 'bold'}}>
                            Liste des évènements passés
                        </Text>
                    </Pressable>
                </View>
            </View>

            <View>
                <View style={[styles.row, {backgroundColor: '#F3F3F3'}]}>
                    <Pressable style={styles.cell} onPress={() => handleSort('date')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'date' ? (sortOrder.order === 'asc' ? 'Date ▲' : 'Date ▼') : 'Date'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('eventName')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'eventName' ? (sortOrder.order === 'asc' ? 'Nom de l\'évènement ▲' : 'Nom de l\'évènement  ▼') : 'Nom de l\'évènement '}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('orgName')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'orgName' ? (sortOrder.order === 'asc' ? 'Nom de l\'organisation ▲' : 'Nom de l\'organisation ▼') : 'Nom de l\'organisation'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('present')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'present' ? (sortOrder.order === 'asc' ? 'Présent ▲' : 'Présent ▼') : 'Présent'}</Text>
                    </Pressable>


                </View>
                {/*{*/}
                {/*    filteredData.map((item, key) => (*/}
                {/*        <View key={key} >*/}
                {/*            <View style={[key %2 == 0 ? styles.white : styles.backgroundRow, {width: '95%'}]}>*/}
                {/*                <Pressable style={{width: '100%', flexDirection: 'row', paddingLeft: 25, paddingVertical: 10}} onPress={() => getItemId(item.id)}>*/}
                {/*                    <Text style={styles.cell}>{item.date}</Text>*/}
                {/*                    <Text style={styles.cell}>{item.eventName}</Text>*/}
                {/*                    <Text style={styles.cell}>{item.orgName}</Text>*/}
                {/*                    <Text style={styles.cell}>{item.present}</Text>*/}
                {/*                </Pressable>*/}
                {/*            </View>*/}
                {/*            {*/}
                {/*                <AllModals*/}
                {/*                    data={tableData[key]}*/}
                {/*                    setModal={setModal}*/}
                {/*                    modal={modal}*/}
                {/*                    itemId={item.id}*/}
                {/*                    getId={getId}*/}
                {/*                />*/}
                {/*            }*/}
                {/*        </View>*/}
                {/*    ))*/}
                {/*}*/}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: '65%',
        flexDirection: "column",
        marginLeft: '10%'
    },
    headerTab: {
        width: '100%',
        marginBottom: 50
    },
    headerTabContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '85%',
        marginLeft: 30,
        alignItems: 'center'
    },
    buttonHeaderTab: {
        marginHorizontal: 10,
        backgroundColor: '#EEEEEE',
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    textButton: {
        fontSize: 13,
        color: '#0F1C2A',
        fontWeight: 'bold'
    },
    white: {
        backgroundColor: 'white'
    },
    backgroundRow: {
        backgroundColor: '#F3F3F3',
    },
    cell: {
        flex: 1,
        textAlign: 'left',
        marginLeft: 40
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingLeft: 25,
        width: '95%',
    },
    nameCol: {
        fontSize: 13,
        fontWeight: 'bold'
    },
    rowHovered: {
        filter: 'blur(1.5px)',
    },
    buttonTab: {
        flexDirection: 'row',
        position: 'absolute',
        left: '22%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999
    },
    buttonG: {
        backgroundColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    buttonD: {
        backgroundColor: '#0B1B2B',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginLeft: 10
    },
    hoveredButtonG: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    hoveredButtonD: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
})

export default InvitationWeb;
