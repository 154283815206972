import React, { useState } from 'react';
import {View, TextInput, Button, StyleSheet, Text, Pressable} from 'react-native';
import {api} from "../../../config/axios";
import {COLORS} from "../../../constants/colors";

const AddBoatsWeb = ( {token} ) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [margin, setMargin] = useState('');
  const [capacity, setCapacity] = useState('');

  const handleSubmit = () => {
    // setName('');
    // setPrice('');
    // setMargin('');
    // setCapacity('');

    const boatsInfos = {
      name: name,
      price: price,
      margin: margin,
      capacity: capacity,
    }
    if(name && price && margin && capacity){
      api.post('boats', boatsInfos, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
          .then(res => console.log(res))
          .catch((error) => console.log(error))
    }

  };

  const handleCancel = () => {

    setName('');
    setPrice('');
    setMargin('');
    setCapacity('');
  };

  return (
      <View style={styles.container}>
        <Text style={styles.title}>Ajout d'un bateau</Text>

        <View style={styles.fieldContainer}>
          <Text style={styles.label}>Nom du bateau : </Text>
          <TextInput
              style={styles.input}
              placeholder="Entrer le nom du bateau"
              value={name}
              onChangeText={setName}
          />
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.label}>Prix du bateau : </Text>
          <TextInput
              style={styles.input}
              placeholder="Entrer le prix"
              value={price}
              onChangeText={setPrice}
              keyboardType="numeric"
          />
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.label}>Marge : </Text>
          <TextInput
              style={styles.input}
              placeholder="Entrer la marge"
              value={margin}
              onChangeText={setMargin}
              keyboardType="numeric"
          />
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.label}>Capacité du bateau : </Text>
          <TextInput
              style={styles.input}
              placeholder="Entrer la capacité du bateau"
              value={capacity}
              onChangeText={setCapacity}
              keyboardType="numeric"
          />
        </View>

        <View style={styles.buttonContainer}>
          <Pressable style={{height: 50, width: 100, backgroundColor: COLORS.opiomMainColor, alignItems: 'center', justifyContent: 'center', marginHorizontal: 10,}} onPress={handleSubmit}>
            <Text style={{color: '#ffff'}}>Créer</Text>
          </Pressable>
        </View>
      </View>
  );
  };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    width: '50%'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
  },
  fieldContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  label: {
    fontWeight: 'bold',
    width: 120,
    marginVertical: 10
  },
  input: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    width: '50%'
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 16,
    alignSelf: 'center'
  },
});

export default AddBoatsWeb;