import {Platform} from "react-native";
import CreditAccountWeb from "../Web/Component/Profil/CreditAccountWeb";
import CreditAccountMobile from "../Mobile/Components/Profil/CreditAccountMobile";

const CreditAccount = () => {
    return(
        Platform.OS === 'web' ?
            <CreditAccountWeb />
            :
            <CreditAccountMobile />

    )
}

export default CreditAccount