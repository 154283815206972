import * as React from 'react';
import { Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import {COLORS} from "../../../../constants/colors";


function ModalConfirmEventWeb({ data, setModal } ) {
    const [showModal, setShowModal] = useState(true)


    return (
        <Modal
            transparent={true}
            visible={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
        >
            <View style={styles.modalContainer}></View>
            <View style={styles.modalContent}>
                <Text style={{color: COLORS.opiomMainColor, fontSize: 15, fontStyle: 'Italic'}}>
                    Félicitations ! Vous venez de confirmer votre présence à l'évènement
                    <Text style={{fontWeight: 'bold'}}> {data.eventName} </Text>
                    organisé par
                    <Text style={{fontWeight: 'bold'}}> {data.orgName}</Text>
                </Text>
                <Pressable style={styles.acceptButton} onPress={() => setModal(0)}>
                    <Text style={{fontSize: 15, color: 'white', fontWeight: 'bold'}}>Fermer</Text>
                </Pressable>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalContent: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        width: 400,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 2,
        position: 'absolute',
        top: 200,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 50,
    },
    modalContainer: {
        backgroundColor: '#002759',
        opacity: 0.5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    acceptButton: {
        marginTop: 40,
        paddingVertical: 10,
        width: 100,
        backgroundColor: COLORS.opiomMainColor,
        alignItems: 'center'
    },
})

export default ModalConfirmEventWeb;
