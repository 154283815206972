import { Platform } from "react-native";
import {useState} from "react";
import TabListEventWeb from "../Web/Component/TabListEventWeb";
import TabListEvent from "../Mobile/Components/TabListEvent";
function ListEvent( { navigation } ) {

    const [tableData, setTableData] = useState([
        {id: '1', nameEvent: 'Soirée Anniversaire', nameOrg: 'BackStage', dateEvent: '01/02/2023', typeOrd: 'Aerso', lastUpdate: '1/03/2023'},
        {id: '2', nameEvent: 'Soirée', nameOrg: 'Rest-e', dateEvent: '02/02/2023', typeOrd: 'Eerso', lastUpdate: '4/03/2023'},
        {id: '3', nameEvent: 'Will', nameOrg: 'B', dateEvent: '07/02/2023', typeOrd: 'Oerso', lastUpdate: '2/03/2023'},
        {id: '4', nameEvent: 'Paul', nameOrg: 'R', dateEvent: '04/02/2023', typeOrd: 'Perso', lastUpdate: '5/03/2023'},
        {id: '5', nameEvent: 'Moi', nameOrg: 'T', dateEvent: '05/02/2023', typeOrd: 'Perso', lastUpdate: '9/03/2023'},
    ])


    return (
            Platform.OS === 'web'?
                <TabListEventWeb
                    tableData={tableData}
                    setTableData={setTableData}
                />
             :
                <TabListEvent
                    tableData={tableData}
                    setTableData={setTableData}
                />
    )
}

export default ListEvent;
