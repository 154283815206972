import {Dimensions, SafeAreaView, ScrollView, StyleSheet, Text, View} from "react-native";
import Header from "../HeaderScreen";
import ChoiceBoat from "../../../Pages/CreateEventPages/ChoiceBoat";
import ChoiceHoraire from "../../../Pages/CreateEventPages/ChoiceHoraire";
import NombresPersonnes from "../../../Pages/CreateEventPages/NombresPersonnes";
import FinalRecap from "../../../Pages/CreateEventPages/FinalRecap";
import InvitPerson from "../../../Pages/CreateEventPages/InvitPerson";
import ChoiceOrg from "../../../Pages/CreateEventPages/ChoiceOrg";
import React from "react";
import NextStep from "./NextStep";
import SuiviEtape from "../../../Web/Component/CreateEventComponents/SuiviEtape";

const CreateEventMobile = ( { idUser, isPro, isSelectedBoat, isSelectedTime, setIsSelectedBoat, setBoatName, setNameEvent, setSelectDay, setIsSelectedTime, maxPers, setDuration, setHoraire, setMaxPers, setOrgId, setState, state, selectDay, nameEvent, boatName, horaire, orgId} ) => {

    return(
            <SafeAreaView style={styles.container}>
                <View style={{zIndex: 1}}>
                    <Header />
                </View>
                <View style={{alignItems: 'center', marginVertical: 10}}>
                    <SuiviEtape
                        state={state}
                    />
                </View>
                    {
                        state === 1 && isPro ? <ChoiceOrg
                                idUser={idUser}
                                setOrgId={setOrgId}
                            />
                            :
                            state === 2 ? <ChoiceBoat
                                    isSelectedBoat={isSelectedBoat}
                                    setIsSelectedBoat={setIsSelectedBoat}
                                    setBoatName={setBoatName}
                                    nameEvent={nameEvent}
                                    setNameEvent={setNameEvent}
                                />
                                :
                                state === 3 ? <ChoiceHoraire
                                        isSelectedTime={isSelectedTime}
                                        setIsSelectedTime={setIsSelectedTime}
                                        setHoraire={setHoraire}
                                        setSelectDay={setSelectDay}
                                        selectDay={selectDay}
                                        setDuration={setDuration}
                                    />
                                    :
                                    state === 4 ? <NombresPersonnes
                                            setMaxPers={setMaxPers}
                                            maxPers={maxPers}
                                        />
                                        :
                                        state === 5 ?
                                            <FinalRecap
                                                setState={setState}
                                            />
                                            :
                                            state === 6 &&
                                            <InvitPerson />

                    }
                    <View style={{alignItems: 'center'}}>
                        <NextStep
                            setState={setState}
                            state={state}
                            boatName={boatName}
                            horaire={horaire}
                            selectDay={selectDay}
                            maxPers={maxPers}
                            orgId={orgId}
                        />
                    </View>
            </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        height: Dimensions.get('window').height,
        backgroundColor: '#ffff',
        position: "relative",
        zIndex: 0
    }
})

export default CreateEventMobile;
