import {Image, StyleSheet, Text, TextInput, View} from "react-native";
import {useRef} from "react";

const NombresPersonnesMobile = ( { setMaxPers, maxPers } ) => {
    const val1 = useRef(0);
    const val2 = useRef(0);
    const val3 = useRef(0);
    const val4 = useRef(0);

    const increment = (id) => {
        if(maxPers < 200){
            if (id === 1) {
                val1.current += 1;
            }else if (id === 2){
                val2.current += 1;
            }else if (id === 3){
                val3.current += 1;
            }else if (id === 4){
                val4.current += 1;
            }
        }
        setMaxPers(val1.current + val2.current + val3.current + val4.current)
    }

    const decremente = (id) => {
        if(maxPers > 0){
            if (id === 1 && val1.current > 0) {
                val1.current -= 1;
            }else if (id === 2 && val2.current > 0){
                val2.current -= 1;
            }else if (id === 3 && val3.current > 0){
                val3.current -= 1;
            }else if (id === 4 && val4.current > 0){
                val4.current -= 1;
            }
        }
        setMaxPers(val1.current + val2.current + val3.current + val4.current)
    }

    const handleChangeText = (id, value) => {
        let intValue = parseInt(value) > 200 ? 200 : parseInt(value) < 0 || value.length === 0 ? 0 : parseInt(value)
        if(maxPers >= 0){
            if (id === 1) {
                val1.current = intValue;
            }else if (id === 2){
                val2.current = intValue;
            }else if (id === 3){
                val3.current = intValue;
            }else if (id === 4){
                val4.current = intValue;
            }
        }
        setMaxPers(val1.current + val2.current + val3.current + val4.current)
    }

    return(
        <View style={styles.container}>
            <View style={{marginTop: 50}}>
                <View style={{flexDirection: 'row', alignItems: 'center', width: '95%'}}>
                    <Image style={{height: 25, width: 25}} resizeMode={'contain'} source={require('../../../../assets/images/people-fill2x.png')} />
                    <Text style={{marginLeft: 10, fontSize: 20}}>Sélectionnez le nombres de personnes </Text>
                </View>
                <Text style={{marginLeft: 40, fontSize: 10, marginTop: 5, opacity: 0.6}}>Pour ce bateau, le nombres de personnes autorisées est de 200 maximum</Text>
            </View>

            <View style={{alignItems: 'flex-end', width: '65%', marginTop: 40}}>
                <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 10}}>
                    <Text style={{fontSize: 16, paddingVertical: 5}}>Adultes</Text>
                    <Text style={{marginLeft: 40, paddingVertical: 5}} onPress={() => decremente(1)} keyboardType="numeric">-</Text>
                    <TextInput style={styles.inputVal} value={val1.current.toString()} onChangeText={(newVal) =>  handleChangeText(1, newVal)}/>
                    <Text style={{paddingVertical: 5}} onPress={() => increment(1)} keyboardType="numeric">+</Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 10}}>
                    <Text style={{fontSize: 16, paddingVertical: 5}}>Enfants 0 - 6 ans</Text>
                    <Text style={{marginLeft: 40, paddingVertical: 5}} onPress={() => decremente(2)} keyboardType="numeric">-</Text>
                    <TextInput style={styles.inputVal} value={val2.current.toString()} onChangeText={(newVal) =>  handleChangeText(2, newVal)}/>
                    <Text style={{paddingVertical: 5}} onPress={() => increment(2)} keyboardType="numeric">+</Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 10}}>
                    <Text style={{fontSize: 16, paddingVertical: 5}}>Enfants 6 - 12 ans</Text>
                    <Text style={{marginLeft: 40, paddingVertical: 5}} onPress={() => decremente(3)} keyboardType="numeric">-</Text>
                    <TextInput style={styles.inputVal} value={val3.current.toString()} onChangeText={(newVal) =>  handleChangeText(3, newVal)}/>
                    <Text style={{paddingVertical: 5}} onPress={() => increment(3)} keyboardType="numeric">+</Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 10}}>
                    <Text style={{fontSize: 16, paddingVertical: 5, textAlign: 'left'}}>Enfants 12 - 18 ans</Text>
                    <Text style={{marginLeft: 40, paddingVertical: 5}} onPress={() => decremente(4)} keyboardType="numeric">-</Text>
                    <TextInput style={styles.inputVal} value={val4.current.toString()} onChangeText={(newVal) =>  handleChangeText(4, newVal)}/>
                    <Text style={{paddingVertical: 5}} onPress={() => increment(4)} keyboardType="numeric">+</Text>
                </View>
            </View>
            {
                maxPers === 200 &&
                <Text style={{color: 'red', fontSize: 16, fontWeight: '500', textAlign: 'center', marginTop: 30}}>Vous avez atteint le nombre autorisé pour ce bateau</Text>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        // backgroundColor: 'blue'
    },
    inputVal: {
        width: 50,
        backgroundColor: '#F3F3F3',
        marginHorizontal: 20,
        textAlign: 'center',
        borderRadius: 25,
        paddingVertical: 5,
        fontWeight: 'bold'
    }
})

export default NombresPersonnesMobile;
