import React, { useState } from "react";
import { Text, View, StyleSheet, Pressable } from "react-native";
import ProfilMenu from "./ProfilMenu";
import Menu from "../Menu";

export default function ProfilMobile({isProfilMenuOpen, onChange} ) {
  return(
    <>
      <View style={styles.profil}>
        <Pressable onPress={onChange}>
          <Text style={{color: 'white', fontSize: 18}}>
            Bonjour <Text style={{fontWeight: 'bold'}}>Thomas Edison </Text>
          </Text>
        </Pressable>
      </View>
      {
        isProfilMenuOpen && <Menu />
      }
    </>
  )
}

const styles = StyleSheet.create({
  profil: {
    backgroundColor: '#002759',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: '100%',
    shadowColor: '#00000029',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 20,
  }
})
