import {Platform} from "react-native";
import NombresPersonnesWeb from "../../Web/Component/CreateEventComponents/NombresPersonnesWeb";
import NombresPersonnesMobile from "../../Mobile/Components/CreateEventComponents/NombresPersonnesMobile";

const NombresPersonnes = ( { setMaxPers, maxPers, boatCapacity } ) => {
    return(
        Platform.OS === 'web' ?
        <NombresPersonnesWeb
            setMaxPers={setMaxPers}
            maxPers={maxPers}
            boatCapacity={boatCapacity}
        />
        :
        <NombresPersonnesMobile
            setMaxPers={setMaxPers}
            maxPers={maxPers}
        />
    )
}

export default NombresPersonnes;
