import React, { useState } from "react";
import {Text, View, StyleSheet, Image, Pressable, Dimensions} from "react-native";
import Menu from './Menu'
import ProfilMobile from "./Profil/ProfilMobile";
import ProfilMenu from "./Profil/ProfilMenu";
import {COLORS} from "../../constants/colors";
import {useNavigation} from "@react-navigation/native";

function HeaderScreen() {
  const [active, setActive] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfilMenuOpen, setIsProfilMenuOpen] = useState(false);

  const handleMenu = () => {
    setActive(!active);
    setIsMenuOpen(!isMenuOpen);
    if(isProfilMenuOpen===true){
      setIsProfilMenuOpen(false)
    }
  }

  function handleProfilMenu() {
    setIsProfilMenuOpen(!isProfilMenuOpen);
    setActive(false)
    if(isMenuOpen===true){
      setIsMenuOpen(false)
    }
  }

  return (
    <>
      <View style={[styles.headerNav, isMenuOpen && {shadowOpacity: 0, elevation: 0}]}>
        <View style={{width: '70%', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center'}}>
          <Image style={{width: 100, height: 100}} resizeMode='center' source={require('../../../assets/images/logo-header-mobile.png')}/>
          <Pressable onPress={handleMenu}>
            <View style={{width: 50, alignItems: 'center'}}>
              <View style={[
                styles.iconeHamb,
                active && isMenuOpen && {transform: [{rotate: '45deg'}], marginVertical: 0}
              ]}></View>
              <View style={{position: 'relative'}}>
                <View style={[
                  styles.barreMilieu,
                  active && isMenuOpen && {opacity: 0}
                ]}></View>
              </View>
              <View style={[
                styles.iconeHamb,
                active && isMenuOpen && {transform: [{rotate: '-45deg'}], marginVertical: -5}
              ]}></View>
            </View>
          </Pressable>
        </View>
      </View>
      <View style={{alignItems: 'center'}}>
        {
          isMenuOpen &&
            <View style={{position: 'absolute', width: Dimensions.get('window').width, zIndex: 1}}>
              <ProfilMenu
                  isOpen={isMenuOpen}
              />
            </View>
        }
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  headerNav: {
    width: '100%',
    height: 60,
    backgroundColor: COLORS.opiomMainColor,
    alignItems: 'center',
    shadowColor: '#00000029',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 20,
  },
  iconeHamb: {
    height: 5,
    width: 40,
    backgroundColor: '#ffff',
    marginVertical: 10
  },
  barreMilieu: {
    height: 5,
    width: 40,
    backgroundColor: '#ffff',
    position: 'absolute',
    bottom: -2.5,
    left: -10
  }
})

export default HeaderScreen;
