import {Platform} from "react-native";
import LoginMobile from "../Components/Identification/LoginMobile";
import LoginWeb from "../../Web/Component/Identification Web/LoginWeb";

const Login = () => {
    return(
        Platform.OS === 'web' ?
            <LoginWeb />
            :
            <LoginMobile />
    )
}

export default Login
