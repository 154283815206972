import React, { useEffect, useRef, useState } from "react";
import {View, StyleSheet, Animated, Text, TouchableOpacity, Pressable, Platform} from "react-native";
import {COLORS} from "../../../constants/colors";
import * as SecureStore from "expo-secure-store";
import Cookies from "universal-cookie/es6";
import {useNavigation} from "@react-navigation/native";
import {Link} from "react-router-dom";


function ProfilMenu() {
  const [connected, setConnected] = useState(true);
  const navigation = Platform.OS !== 'web' && useNavigation()
  const LogOut = async () => {
    if(Platform.OS === 'web'){
      const cookies = new Cookies();
      cookies.remove('jwtToken');
      window.reload()
    }else {
      await SecureStore.deleteItemAsync('jwtToken')
    }
  }

  return (
    Platform.OS !== 'web' ?
      <View style={styles.container}>
        <View style={{alignItems: 'center', marginTop: 30}}>
          <Pressable onPress={() => navigation.navigate('Profil')}>
            <Text style={[styles.menuItems, {fontWeight: 'bold'}]}>Mon profil utilisateur</Text>
          </Pressable>
          <Pressable onPress={() => navigation.navigate('ListEvent')}>
            <Text style={styles.menuItems}>Liste des évènements</Text>
          </Pressable>
          <Pressable onPress={() => navigation.navigate('CreateEvent')}>
            <Text style={styles.menuItems}>Créer un évènement</Text>
          </Pressable>
          <Pressable onPress={() => navigation.navigate('CreditAccount')}>
            <Text style={styles.menuItems}>Créditer son compte</Text>
          </Pressable>
        </View>
        <View style={{alignItems: 'center', marginTop: 30}}>
          <Pressable onPress={LogOut}>
            <Text style={[styles.menuItems, {fontWeight: 'bold', marginBottom: 30, padding: 15, backgroundColor: 'white', color: COLORS.opiomMainColor}]}>Se déconnecter</Text>
          </Pressable>
        </View>
      </View>
        :
      <View style={styles.container}>
        <View style={{alignItems: 'center', marginTop: 30}}>
          <View style={styles.navItems}>
            <Link to='/' style={styles.link}>
              <Text style={[styles.menuItems, {fontWeight: 'bold'}]}>Mon profil utilisateur</Text>
            </Link>
          </View>

          <View style={styles.navItems}>
            <Link to='/' style={styles.link}>
              <Text style={styles.menuItems}>Liste des évènements</Text>
            </Link>
          </View>

          <View style={styles.navItems}>
            <Link to='/creation-event' style={styles.link}>
              <Text style={styles.menuItems}>Créer un évènement</Text>
            </Link>
          </View>

        </View>
        <View style={{alignItems: 'center', marginTop: 30}}>
          <Pressable onPress={LogOut}>
            <Text style={[styles.menuItems, {fontWeight: 'bold', marginBottom: 30, padding: 15, backgroundColor: 'white', color: COLORS.opiomMainColor}]}>Se déconnecter</Text>
          </Pressable>
        </View>
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.opiomMainColor,
    shadowColor: '#00000029',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 2,
  },
  menuItems: {
    fontSize: 18,
    color: 'white',
    marginVertical: 15
  },
  link: {
    textDecorationLine: 'none',
    gap: 20
  },
  navItems: {
    marginVertical: 10
  }
});

export default ProfilMenu;
