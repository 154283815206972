import {Pressable, Text, View, StyleSheet, Image} from "react-native";
import {Link} from "react-router-dom";
import {COLORS} from "../../../constants/colors";

const RecapSideEvent = ({ isEventSelected, setState, state, boatName, horaire, selectDay, maxPers, orgId } ) => {
    const changePage = () => {
        if(state === 1 && orgId ) {
            setState(2)
        }else if(state === 2 && boatName) {
            setState(3)
        }else if (state === 3 && horaire && selectDay ){
            setState(4)
        }else if (state === 4 && maxPers > 0){
            setState(5)
        }
    }
    return(
        <View style={styles.sideRecap}>
            <Text style={{fontWeight: 'bold', fontSize: 16}}>Récapitulatif</Text>
                <Text style={{opacity: 0.4, marginTop: 10}}></Text>
            <View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 60}}>
                    <View style={[styles.suivi, state >= 2 && {backgroundColor:  '#0D1B2B'}, {height: 20, width: 20}]}>
                        <Image style={styles.icone} source={require('../../../../assets/images/boat-icone2x.png')} />
                    </View>
                    <Text style={{marginLeft: 20, opacity: state >= 2 ? 1 : 0.4}}>{boatName ? boatName : 'Choisissez le bateau'}</Text>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 20}}>
                    <View style={[styles.suivi, state >= 3 && {backgroundColor:  '#0D1B2B'}, {height: 20, width: 20}]}>
                        <Image style={styles.icone} source={require('../../../../assets/images/heure-icone2x.png')} />
                    </View>
                    <Text style={{marginLeft: 20, opacity: state >= 3 ? 1 : 0.4}}>{horaire && selectDay ? `${selectDay}` : 'Choisissez la date' }</Text>
                </View>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={[styles.suivi, state >= 4 && {backgroundColor:  '#0D1B2B'}, {height: 20, width: 20}]}>
                        <Image style={styles.icone} source={require('../../../../assets/images/user-icone2x.png')} />
                    </View>
                    <Text style={{marginLeft: 20, opacity: state >= 4 ? 1 : 0.4}}>{maxPers ? `${maxPers} personne(s)` : 'Choisissez le nombre'}</Text>
                </View>
            </View>
            <Pressable style={{marginTop: 70, paddingVertical: 5, paddingHorizontal: 20, backgroundColor: '#0D1B2B', borderRadius: 50}} onPress={changePage}>
                <Text style={{color: 'white'}}>Étape suivante</Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    sideRecap: {
        width: 250,
        height: 350,
        marginTop: 40,
        borderRadius: 10,
        backgroundColor: COLORS.gris,
        alignItems: 'center',
        padding: 30
    },
    suivi: {
        height: 30,
        width: 30,
        backgroundColor: '#B6BABF',
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    icone: {
        width: 10,
        height: 10
    }
})

export default RecapSideEvent;
