import {Dimensions, Platform, StyleSheet, View} from "react-native";
import Header from "../Header";
import SuiviEtape from "./SuiviEtape";
import ChoiceBoat from "../../../Pages/CreateEventPages/ChoiceBoat";
import ChoiceHoraire from "../../../Pages/CreateEventPages/ChoiceHoraire";
import RecapSideEvent from "./RecapSideEvent";
import NombresPersonnes from "../../../Pages/CreateEventPages/NombresPersonnes";
import FinalRecap from "../../../Pages/CreateEventPages/FinalRecap";
import InvitPerson from "../../../Pages/CreateEventPages/InvitPerson";
import ChoiceOrg from "../../../Pages/CreateEventPages/ChoiceOrg";
import NextStep from "../../../Mobile/Components/CreateEventComponents/NextStep";
import React, {useState} from "react";
import Footer from "../Footer";


const CreateEventWeb = ( { isPublic, setIsPublic, idEvent, setIdEvent, token, idUser, isPro, boatName, isSelectedBoat, isSelectedTime, setIsSelectedBoat, setBoatName, setNameEvent, nameEvent, setSelectDay, setIsSelectedTime, maxPers, setDuration, setHoraire, setMaxPers, setOrgId, setState, state, selectDay, orgId, horaire, setBoatCapacity, boatCapacity} ) => {

    const [isEventSelected, setIsEventSelected] = useState(null)

    return(
      <View style={{flexDirection: 'column', width: '100%'}}>
        <Header />
          <View style={[state === 5 && {display: 'none'}, Dimensions.get('window').width < 1000 && {marginTop: 40, alignSelf: 'center'}]}>
            <SuiviEtape
                state={state}
            />
          </View>
        <View style={styles.container}>
            {
                state === 1 ?
                    <ChoiceOrg
                        idUser={idUser}
                        setOrgId={setOrgId}
                        token={token}
                        setIsPublic={setIsPublic}
                        setIsEventSelected={setIsEventSelected}
                        isEventSelected={isEventSelected}
                    />
                :
                state === 2 ? <ChoiceBoat
                        isSelectedBoat={isSelectedBoat}
                        setIsSelectedBoat={setIsSelectedBoat}
                        setBoatName={setBoatName}
                        setBoatCapacity={setBoatCapacity}
                        setNameEvent={setNameEvent}
                        nameEvent={nameEvent}
                    />
                    :
                state === 3 ? <ChoiceHoraire
                        isSelectedTime={isSelectedTime}
                        setIsSelectedTime={setIsSelectedTime}
                        setHoraire={setHoraire}
                        setSelectDay={setSelectDay}
                        selectDay={selectDay}
                        setDuration={setDuration}
                    />
                    :
                state === 4 ? <NombresPersonnes
                        setMaxPers={setMaxPers}
                        maxPers={maxPers}
                        boatCapacity={boatCapacity}
                    />
                    :
                state === 5 ?
                    <View style={{alignItems: 'center', justifyContent: 'center', width: '80%'}}>
                        <SuiviEtape state={state}/>
                        <FinalRecap
                            setState={setState}
                        />
                    </View>
                    :
                state === 6 &&
                    <InvitPerson
                        token={token}
                        idEvent={idEvent}
                        setIdEvent={setIdEvent}
                    />

            }
            {
                Dimensions.get('window').width > 800 ?
                    <RecapSideEvent
                        setState={setState}
                        state={state}
                        boatName={boatName}
                        horaire={horaire}
                        selectDay={selectDay}
                        maxPers={maxPers}
                        orgId={orgId}
                        isEventSelected={isEventSelected}
                    />
                    :
                    <View style={{alignItems: 'center', marginTop: state === 4 ? '40%' : state === 3 ? '80%' : state === 2 ? '40%' : state === 1 && '40%' , display: state === 6 && 'none'}}>
                        <NextStep
                            setState={setState}
                            state={state}
                            boatName={boatName}
                            horaire={horaire}
                            selectDay={selectDay}
                            maxPers={maxPers}
                            orgId={orgId}
                            isEventSelected={isEventSelected}
                        />
                    </View>
            }
        </View>
          {
              Dimensions.get("window").width > 1000 &&
                <Footer />
          }
      </View>
  )
}

const styles = StyleSheet.create({
    container: {
        width: '85%',
        alignSelf: 'center',
        alignItems: Dimensions.get('window').width > 800 ? 'center': '',
        flexDirection: Dimensions.get('window').width > 800 ? 'row' : 'column',
        justifyContent: Dimensions.get('window').width > 800 ? 'space-between' : ''
    },
})

export default CreateEventWeb;
