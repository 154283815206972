import {Pressable, StyleSheet, Text, View} from "react-native";
import React from "react";
import {COLORS} from "../../../constants/colors";

const NextStep = ({ setState, state, boatName, horaire, selectDay, maxPers, orgId, isEventSelected } ) => {

    const changePage = () => {
        if(state === 1 && orgId && isEventSelected) {
            setState(2)
        }else if(state === 2 && boatName) {
            setState(3)
        }else if (state === 3 && horaire && selectDay ){
            setState(4)
        }else if (state === 4) {
            setState(6)
        }
    }

  return(
      <Pressable style={styles.buttonContainer} onPress={changePage}>
          <Text style={{textAlign: 'center', color: 'white'}}>Étape suivante</Text>
      </Pressable>
  )
}

const styles = StyleSheet.create({
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: COLORS.opiomMainColor,
        height: 50,
        width: 200,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default NextStep
