import Header from "./Header";
import {Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import {Link} from "react-router-native";
import {Hoverable} from "react-native-web-hover";
import {useState} from "react";
import {COLORS} from "../../constants/colors";

const TabListEventWeb = ({ tableData, setTableData }) => {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            width: '100%',
            flexDirection: "column",
        },
        headerTab: {
            width: '100%',
            alignItems: 'center',
            marginVertical: 50
        },
        headerTabContent: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '70%',
            alignItems: 'center'
        },
        buttonHeaderTab: {
            marginHorizontal: 10,
            backgroundColor: '#EEEEEE',
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 21,
        },
        textButton: {
            fontSize: 13,
            color: '#0F1C2A',
            fontWeight: 'bold'
        },
        white: {
            backgroundColor: 'white'
        },
        backgroundRow: {
            backgroundColor: '#F3F3F3',
        },
        cell: {
            flex: 1,
            textAlign: 'left',
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 5,
            paddingLeft: 25,
            width: '95%',
        },
        nameCol: {
            fontSize: 13,
            fontWeight: 'bold'
        },
        rowHovered: {
            textShadowColor: 'rgba(0, 0, 0, 0.3)',
            textShadowOffset: {width: -1, height: 5},
            textShadowRadius: 10,
            filter: 'blur(1.5px)',
            position: "relative"
        },
        rowNonHovered: {
            opacity: 1
        },
        buttonTab: {
            flexDirection: 'row',
            position: 'absolute',
            left: '22%',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999
        },
        buttonG: {
            backgroundColor: '#D3414A',
            paddingHorizontal: 15,
            paddingVertical: 3,
            marginRight: 10
        },
        buttonD: {
            backgroundColor: '#0B1B2B',
            paddingHorizontal: 15,
            paddingVertical: 3,
            marginLeft: 10
        },
        hoveredButtonG: {
            backgroundColor: 'white',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#D3414A',
            paddingHorizontal: 15,
            paddingVertical: 3,
            marginRight: 10
        },
    })

    const [filterText, setFilterText] = useState('');
    const [sortOrder, setSortOrder] = useState({ column: 'name', order: 'asc' });

    const filteredData = tableData.filter((item) =>
        item.id.includes(filterText) ||
        item.nameEvent.toLowerCase().includes(filterText.toLowerCase()) ||
        item.nameOrg.toLowerCase().includes(filterText.toLowerCase()) ||
        item.dateEvent.includes(filterText) ||
        item.lastUpdate.includes(filterText) ||
        item.typeOrd.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleSort = (column) => {
        const sortedData = tableData.slice().sort((a, b) => {
            if (sortOrder.order === 'asc') {
                return a[column].localeCompare(b[column]);
            } else {
                return b[column].localeCompare(a[column]);
            }
        });
        setTableData(sortedData);
        setSortOrder({ column: column, order: sortOrder.order === 'asc' ? 'desc' : 'asc' });
    };
    return(
        <View style={styles.container}>
            <Header />

            <View style={styles.headerTab}>
                <View style={styles.headerTabContent}>
                    <Text style={{fontSize: 22, color: COLORS.opiomMainColor, fontFamily: 'DMSerifDisplay-Regular'}}>
                        Liste des évènements
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                        <Pressable style={[styles.buttonHeaderTab, {backgroundColor: COLORS.opiomMainColor}]}>
                            <Link to='/choice-org'>
                                <Text style={[styles.textButton ,{color: 'white'}]}>Créer</Text>
                            </Link>
                        </Pressable>
                        <Pressable style={styles.buttonHeaderTab}>
                            <Text style={styles.textButton}>Voir tous</Text>
                        </Pressable>
                        <Pressable style={styles.buttonHeaderTab}>
                            <Text style={styles.textButton}>Filtrer</Text>
                        </Pressable>
                        <TextInput value={filterText} onChangeText={setFilterText} style={[styles.buttonHeaderTab, {color: COLORS.opiomMainColor, fontWeight: '300'}]} placeholder='Rechercher' />
                    </View>
                </View>
            </View>

            <View  style={{alignItems: 'center'}}>
                <View style={[styles.row, {backgroundColor: '#F3F3F3', borderTopLeftRadius: 15, borderTopRightRadius: 15}]}>
                    <Pressable style={styles.cell} onPress={() => handleSort('id')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'id' ? (sortOrder.order === 'asc' ? 'Id ▲' : 'Id ▼') : 'Id'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('nameEvent')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'nameEvent' ? (sortOrder.order === 'asc' ? 'Nom de l\'évènement ▲' : 'Nom de l\'évènement ▼') : 'Nom de l\'évènement'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('nameOrg')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'nameOrg' ? (sortOrder.order === 'asc' ? 'Nom de l\'organisation ▲' : 'Nom de l\'organisation ▼') : 'Nom de l\'organisation'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('dateEvent')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'dateEvent' ? (sortOrder.order === 'asc' ? 'Date de L\'évènement ▲' : 'Date de L\'évènement ▼') : 'Date de L\'évènement'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('typeOrd')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'typeOrd' ? (sortOrder.order === 'asc' ? 'Type d\'organisation ▲' : 'Type d\'organisation ▼') : 'Type d\'organisation'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('lastUpdate')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'lastUpdate' ? (sortOrder.order === 'asc' ? 'Dernière modification ▲' : 'Dernière modification ▼') : 'Dernière modification'}</Text>
                    </Pressable>
                </View>
                {
                    filteredData.map((item, key) => (
                        <Hoverable key={key} style={[key %2 ==0 ? styles.white : styles.backgroundRow, {width: '95%'}]}>
                            {({ hovered }) => (
                                <View style={{width: '100%', flexDirection: 'row', paddingLeft: 25, paddingVertical: 5}}>
                                    {
                                        hovered &&
                                        <View style={styles.buttonTab}>
                                            <Pressable style={styles.buttonG}>
                                                <Text style={{fontSize: 9, color: 'white'}}>Supprimer</Text>
                                            </Pressable>
                                            <Pressable style={styles.buttonD}>
                                                <Text style={{color: 'white', fontSize: 9}}>Consulter</Text>
                                            </Pressable>
                                        </View>
                                    }
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.id}</Text>
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.nameEvent}</Text>
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.nameOrg}</Text>
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.dateEvent}</Text>
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.typeOrd}</Text>
                                    <Text style={[styles.cell, hovered ? styles.rowHovered : styles.rowNonHovered]}>{item.lastUpdate}</Text>
                                </View>
                            )}
                        </Hoverable>
                    ))
                }
            </View>
        </View>
    )
}

export default TabListEventWeb;
