import {ActivityIndicator, Platform, View, Text} from 'react-native';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import CreateEvent from "./src/Pages/CreateEventPages/CreateEvent";
import Profil from "./src/Pages/Profil";
import * as SecureStore from "expo-secure-store";
import {useEffect, useState} from "react";
import Login from "./src/Mobile/Identification/Login";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie/es6";
import Register from "./src/Mobile/Identification/Register";
import {AuthProvider} from "./src/context/authContext";
import AppStack from "./src/Mobile/navigation/AppStack";
import AuthStack from "./src/Mobile/navigation/AuthStack";
import InvitPerson from "./src/Pages/CreateEventPages/InvitPerson";

export default function App() {
    // const Stack = createStackNavigator();
    const [userRole, setUserRole] = useState()
    const [token, setToken] = useState()
    const [idUser, setIdUser] = useState(null)
    const [isPro, setIsPro] = useState()
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
            if(Platform.OS !== 'web'){
                const getValue = async () => { // Récupérer le token stocké dans le secureStore
                    try {
                        const value = await SecureStore.getItemAsync('jwtToken');
                        setToken(value)
                        if(token){
                            const jwtDecode = require('jwt-decode')
                            const decodedToken = jwtDecode(value)
                            setIdUser(decodedToken.id)
                            setIsPro(decodedToken.isPro)
                            const role = decodedToken.role.join(' ')
                            setUserRole(role)
                            setLoading(false)
                        }else {
                            setLoading(false)
                        }
                    } catch (error) {
                        console.log('Erreur lors de la récupération de la valeur:', error);
                    }
                };
                getValue();

            }else {
                const getRoleFromToken =  () => {
                    try {
                        const cookies = new Cookies();
                        const value = cookies.get('jwtToken')
                        setToken(value)
                        if(token) {
                            const decodedToken = jwtDecode(token);
                            setIdUser(decodedToken.id)
                            setIsPro(decodedToken.isPro)
                            const role = decodedToken.role.join(' '); // Remplacez 'role' par la propriété contenant le rôle dans votre token
                            setUserRole(role)
                            setLoading(false)
                        }else {
                            setLoading(false)
                        }
                    } catch (error) {
                        console.log('Une erreur s\'est produite lors du décodage du token :', error);
                        return null;
                    }
                };
                getRoleFromToken();
            }
    },[token])

    if(isLoading){
        return(
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{marginBottom: 10}}>Chargement...</Text>
                <ActivityIndicator size='large' />
            </View>
        )
    }

        return (
            Platform.OS === 'web' ?
                <Router>
                    <Routes>
                        <Route path='/creation-event' element={
                            !token ?
                                <Navigate to='/login' />
                                :
                                <CreateEvent idUser={idUser} isPro={isPro} />}
                        />
                        <Route exact path='/' element={
                            !token ?
                                <Navigate to='/login' />
                                :
                                <Profil role={userRole} idUser={idUser} token={token} />}
                        />
                        <Route exact path='/login' element={
                            !token ?
                                <Login />
                                :
                                <Navigate to='/'/>
                        } />
                        <Route exact path='/register' element={
                            !token ?
                                <Register />
                                :
                                <Navigate to='/'/>
                        } />
                        <Route path='invit-person' element={<InvitPerson idUser={idUser} token={token} />}/>
                    </Routes>
                </Router>
                :
                <AuthProvider>
                    {
                        token ?
                            <AppStack />
                         :
                          <AuthStack />
                     }
                </AuthProvider>
            );
}
