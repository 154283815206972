import {Iframe} from "@bounceapp/iframe";

const CreditAccountWeb = () => {
  return(
      <Iframe uri="https://monespace.wiloutcashless.com/8c0fb78b7428313290f82a3faa2aa4b0/w"  style={{flex: 1}}/>
  //     <WebView
  //         originWhitelist={['*']}
  //         source={{ html: "<iframe src=\"https://monespace.wiloutcashless.com/8c0fb78b7428313290f82a3faa2aa4b0/w%22frameborder=%22none%22allowTransparency=%22true%22style=%22width:100%;min-width:440px;height:570px;margin:auto%22%3E</iframe>" }} />
  )
}

export default CreditAccountWeb