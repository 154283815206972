import {StyleSheet, View, Text, Pressable, Animated, Image, Dimensions} from "react-native";
import {useState} from "react";
import {Link} from "react-router-dom";
import HeaderScreen from "../../Mobile/Components/HeaderScreen";

function Header() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [underlineAnimation] = useState(new Animated.Value(0));
    const [animatedValue] = useState(new Animated.Value(0));

    const handlePress = (index) => {
        setSelectedIndex(index);
        Animated.timing(underlineAnimation, {
            toValue: 1,
            duration: 5000,
            useNativeDriver: false
        }).start();
    };

    const windowWidth = Dimensions.get('window').width
    if(windowWidth > 1000) {
        return (
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={{flexDirection: 'row', padding: 80}}>
                        <Text style={styles.itemHeader}>06 06 06 06 06</Text>
                        <Text style={styles.itemHeader}>Contactez nous</Text>
                    </View>
                </View>
                <View style={styles.navContainer}>
                    <View style={styles.navContent}>
                        <View style={styles.menuNav}>
                            <Pressable>
                                <Image style={{width: 50, height: 50, paddingHorizontal: 30}}
                                       source={require('../../../assets/images/logo-opiomX2.png')}/>
                            </Pressable>
                            <View style={styles.navItem}>
                                <Pressable style={[styles.borderBottom, selectedIndex === 0 && {borderColor: 'black'}]}
                                           onPress={() => handlePress(0)}>
                                    <Text style={selectedIndex === 0 ? styles.selectedText : styles.textNav}>mes
                                        événements</Text>
                                </Pressable>
                                <Pressable style={[styles.borderBottom, selectedIndex === 1 && {borderColor: 'black'}]}
                                           onPress={() => handlePress(1)}>
                                    <Text style={selectedIndex === 1 ? styles.selectedText : styles.textNav}>mes
                                        organisations</Text>
                                </Pressable>
                                <Pressable style={[styles.borderBottom, selectedIndex === 2 && {borderColor: 'black'}]}
                                           onPress={() => handlePress(2)}>
                                    <Text style={selectedIndex === 2 ? styles.selectedText : styles.textNav}>gestion des
                                        offres</Text>
                                </Pressable>
                                <Pressable style={[styles.borderBottom, selectedIndex === 3 && {borderColor: 'black'}]}
                                           onPress={() => handlePress(3)}>
                                    <Text
                                        style={selectedIndex === 3 ? styles.selectedText : styles.textNav}>membres</Text>
                                </Pressable>

                            </View>
                            <View style={styles.navItem}>
                                <Pressable>
                                    <Link to='/creation-event' style={{textDecorationLine: 'none'}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <View style={styles.suivi}>
                                                <Image style={{width: 13, height: 13}} resizeMode='contain' source={require('../../../assets/images/boat-icone2x.png')} />
                                            </View>
                                            <Text style={[styles.textNav, styles.paramNav]}>créer un événement</Text>
                                        </View>
                                    </Link>
                                </Pressable>

                                <Pressable>
                                    <Link to='/' style={{textDecorationLine: 'none', flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <View style={styles.suivi}>
                                                <Image style={{width: 13, height: 13}} resizeMode='contain' source={require('../../../assets/images/user-icone2x.png')} />
                                            </View>
                                            <Text style={[styles.textNav, styles.paramNav]}>mon profil</Text>
                                        </View>
                                    </Link>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>

            </View>

        )
    }else {
        return (
            <View style={{zIndex: 300}}>
                <HeaderScreen />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: 187
    },
    header: {
        backgroundColor: '#0D1C2B',
        width: '100%',
        height: 50,
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    itemHeader: {
        color: 'white',
        fontSize: 18,
        paddingHorizontal: 10
    },
    navContainer: {
        height: 137,
        width: '100%',
    },
    navContent: {
        height: 120,
        justifyContent: 'center',
        alignItems: 'center',
    },
    suivi: {
        height: 20,
        width: 20,
        backgroundColor: 'red',
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',

    },
    menuNav: {
        width: '90%',
        height: 50,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navItem: {
        flexDirection: 'row',
        position: 'relative'
    },
    textNav: {
        textTransform: 'uppercase',
        padding: 10
    },
    selectedText: {
        textTransform: 'uppercase',
        padding: 10,
        fontWeight: '600'
    },
    paramNav: {
        color: '#D3414A',
        fontWeight: 'bold'
    },
    underline: {
        width: '100%',
        backgroundColor: 'black',
        marginTop: 5,
    },
    borderBottom: {
        borderBottomWidth: 1,
        borderColor: '#E5E5E5',
        borderStyle: 'solid',
    }
})

export default Header;
