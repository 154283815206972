import {Image, Pressable, ScrollView, StyleSheet, Text, TextInput, View} from "react-native";
import {COLORS} from "../../../constants/colors";
import {useState} from "react";

const ChoiceBoatMobile = ( { data, isSelectedBoat, setBoatName, setBoatCapacity, setIsSelectedBoat } ) => {

    const [selectId, setSelectId] = useState(null)
    const [changeName, setChangeName] = useState(false)

    const handleEvent = (id, name) => {
        if(isSelectedBoat === id) {
            setIsSelectedBoat(null)
            setSelectId(null)
            setBoatName(null)
        }else{
            setIsSelectedBoat(id)
            setBoatName(name)
        }
    }

    return(
        <View style={styles.container}>

            <View style={{marginTop: 20}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/boat2x.png')} />
                    <Text style={{marginLeft: 20, fontSize: 20}}>Choisissez votre bateau</Text>
                </View>
            </View>
            <View style={styles.boatContainer}>
            <ScrollView  contentContainerStyle={{alignItems: 'center'}}>
                {
                    data?.map((item) => (
                        <Pressable key={item.id} style={[styles.card, isSelectedBoat === item.id && {backgroundColor: '#0D1B2B'}]} onPress={() => handleEvent(item.id, item.name)}>
                            <View style={styles.imageBateau} />
                            <View style={{width: '80%', alignSelf: 'center'}}>
                                <Text style={[{marginTop: 5}, isSelectedBoat === item.id && {color: 'white'}]}>{item.name}</Text>
                                <Text style={[{marginTop: 5, marginBottom: 20}, isSelectedBoat === item.id && {color: 'white'}]}>{`Capacité : ${item.capacity}`}</Text>
                            </View>
                        </Pressable>
                    ))
                }
            </ScrollView>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '90%',
        alignSelf: 'center',
        alignItems: 'center',
    },
    boatContainer: {
        alignItems: 'center',
        height: '75%',
        width: '80%',
        // backgroundColor: 'red'
    },
    h1: {
        textTransform: 'uppercase',
        fontSize: 20,
        opacity: 0.3,
        marginVertical: 5,
        marginRight: 10
    },
    card: {
        width: 150,
        marginBottom: 30,
        borderRadius: 10
    },
    imageBateau: {
        backgroundColor: COLORS.gris,
        width: '80%',
        height: 130,
        borderRadius: 10,
        alignSelf: 'center',
        marginVertical: 15
    },
})

export default ChoiceBoatMobile
