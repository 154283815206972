import React from 'react';
import { View, Image, StyleSheet, Dimensions, SafeAreaView } from "react-native";

function Header() {

    return (
        <View>
            <View style={styles.header}>
                <Image style={styles.circleHeader} resizeMode='stretch' source={require('../../../../assets/images/arc-cercle.png')} />
                <View style={styles.logo}>
                    <Image style={{height: 100, width: 100}} resizeMode='center' source={require('../../../../assets/images/logo-opiomX2.png')} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    logo: {
        position: 'absolute',
        alignSelf: 'center',
        top: 40
    },
    header: {
        position: 'relative',
        marginBottom: 50
    },
    circleHeader: {
        width: Dimensions.get('window').width,
        minHeight: 200,
        top: 0
    },
});
export default Header;
