import HeaderProfileWeb from "./HeaderProfilWeb";
import {Dimensions, StyleSheet, View} from "react-native";
import LeftMenu from "./LeftMenu";
import {useEffect, useState} from "react";
import ProfilContentWeb from "./ProfilContentWeb";
import TabLastEventUserWeb from "./TabLastEventUserWeb";
import Header from "../Header";
import ConsoUserTabWeb from "./ConsoUserTabWeb";
import InvitationWeb from "./InvitationWeb";
import CreditAccount from "../../../Pages/CreditAccount";
import AddBoatsWeb from "./AddBoatsWeb";
import UserProfil from "../../../Pages/UserProfil";
import ProfilForm from "../../../Mobile/Components/Profil/ProfilForm";

const ProfilWeb = ( { role, data, token, idUser } ) => {
    const [state, setState] = useState(1);
    const [invitation, setInvitation] = useState(false);

    return(
        <View style={{flexDirection: 'column', width: '100%'}}>
            <Header />
            <View style={styles.container}>
                <View style={Dimensions.get("window").width < 1000 && {display: 'none'}}>
                    <LeftMenu
                        state={state}
                        setState={setState}
                        role={role}
                        idUser={idUser}
                        data={data}
                    />
                </View>
                    {
                        state === 1 ?
                            <ProfilContentWeb
                                data={data}
                                token={token}
                                idUser={idUser}
                            />
                            :
                            state === 2 ?
                            !invitation ?
                            <TabLastEventUserWeb
                                setInvitation={setInvitation}
                                userData={data}
                                token={token}
                            />
                            :
                            <InvitationWeb
                                setInvitation={setInvitation}
                            />
                            :
                            state === 3 ?
                                <ConsoUserTabWeb />
                            :
                            state === 4 &&
                            <View style={{height: 900, alignSelf: 'center', width: 800, marginLeft: '15%'}}>
                                <CreditAccount />
                            </View>
                    }
                    {
                        role === 'ROLE_ADMIN' &&
                            state === 8 ?
                            <AddBoatsWeb token={token}  />
                            :
                            <></>
                    }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        marginTop: 80,
        width: '90%',
        flexDirection: 'row'
    }
})

export default ProfilWeb;
