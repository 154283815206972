import {Pressable, StyleSheet, Text, View} from "react-native";
import React, {useEffect, useState} from "react";
import {COLORS} from "../../../constants/colors";
import Cookies from "universal-cookie/es6";
import {useNavigate} from "react-router-dom";
import {api} from "../../../config/axios";

const LeftMenu = ( { state, role, setState, idUser, data } ) => {
    const [itemUser, setItemUser] = useState(['Mon profil utilisateur', 'Liste des évènements', 'Liste des consommations', 'Créditer mon compte'])
    const [itemAdmin, setItemAdmin] = useState(['Mon profil utilisateur', 'Liste des évènements', 'Liste des consommations', 'Créditer mon compte', 'Liste des organisations', 'Liste des utilisateurs', 'Liste des évènements', 'Ajouter un bateau'])
    const [getKey, setKey] = useState('')
    const userDisconnect = () => {
        const cookies = new Cookies();
        cookies.remove('jwtToken');
        window.location.reload()
    }

    return(
        <View style={styles.container}>
            <View>
                <View style={styles.card}>
                    <View style={{backgroundColor: 'white'}} />
                </View>
                <Text style={[styles.text, {textAlign: 'center'}]}>Bonjour <Text style={{fontWeight: 'bold'}}>{`${data.firstName} ${data.lastName}`}</Text></Text>
                <Text style={[styles.text, {textTransform: 'capitalize', fontWeight: '100', textAlign: 'center', fontStyle: 'italic'}]}>
                    {
                        role === 'ROLE_USER' ?
                            'Utilisateur'
                            :
                            role === 'organisateur' ?
                                'Organisateur'
                                :
                                role === 'ROLE_ADMIN' &&
                                'Administrateur'
                    }
                </Text>
            </View>
            <View style={{marginTop: 30}}>
                {
                    role === 'ROLE_USER' ?
                        itemUser.map((item, key) => (
                            <Pressable key={key} style={{marginVertical: 10}} onPress={() => key === 0 ? setState(1) : key === 1 ? setState(2) : key === 2 ? setState(3) : key === 3 && setState(4)}>
                                <Text style={[styles.text,
                                    state === 1 && key === 0 ? {fontWeight: 'bold'} :
                                        state === 2 && key === 1 ? {fontWeight: 'bold'} :
                                            state === 3 && key === 2 ? {fontWeight: 'bold'} :
                                                state === 4 && key === 3 && {fontWeight: 'bold'}]}
                                >
                                    {item}
                                </Text>
                            </Pressable>
                        ))
                        :

                        role === 'ROLE_ADMIN' &&
                            itemAdmin.map((item, key) => (
                                <Pressable key={key} style={{marginVertical: 10}} onPress={() => key === 0 ? setState(1) : key === 1 ? setState(2) : key === 2 ? setState(3) : key === 3 ? setState(4) : key === 4 ? setState(5) : key === 5 ? setState(6) : key === 6 ? setState(7) : key === 7 && setState(8) }>
                                    <Text style={[styles.text,
                                        state === 1 && key === 0 ? {fontWeight: 'bold'} :
                                            state === 2 && key === 1 ? {fontWeight: 'bold'} :
                                                state === 3 && key === 2 ? {fontWeight: 'bold'} :
                                                    state === 4 && key === 3 ? {fontWeight: 'bold'} :
                                                        state === 5 && key === 4 ? {fontWeight: 'bold'} :
                                                            state === 6 && key === 5 ? {fontWeight: 'bold'} :
                                                                state === 7 && key === 6 ? {fontWeight: 'bold'} :
                                                                    state === 8 && key === 7 && {fontWeight: 'bold'}
                                    ]}
                                    >
                                        {item}
                                    </Text>
                                </Pressable>
                            ))
                }
            </View>
            <View style={{alignSelf: 'center', marginTop: 50}}>
                <Pressable style={styles.btn} onPress={userDisconnect}>
                    <Text style={[styles.text, {textAlign: 'center', color: 'white'}]}>
                        Se déconnecter
                    </Text>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 25,
        paddingBottom: 50,
        paddingVertical: 50,
        paddingHorizontal: 30,
        width: 350,
        backgroundColor: COLORS.gris,
    },
    text: {
        color: COLORS.opiomMainColor,
        fontSize: 18,
    },
    btn: {
        height: 40,
        width: 250,
        justifyContent: 'center',
        backgroundColor: COLORS.opiomMainColor
    },
    card: {
        backgroundColor: 'white',
        height: 50,
        width: 50,
        borderStyle: 'solid',
        borderRadius: 25,
        borderColor: COLORS.gris,
        alignSelf: 'center',
        marginBottom: 10
    },
})

export default LeftMenu;
