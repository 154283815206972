import {Platform} from "react-native";
import CreateEventWeb from "../../Web/Component/CreateEventComponents/CreateEventWeb";
import CreateEventMobile from "../../Mobile/Components/CreateEventComponents/CreateEventMobile"
import {useEffect, useState} from "react";
import Cookies from "universal-cookie/es6";
import {api} from "../../config/axios";
import * as SecureStore from "expo-secure-store";
import jwtDecode from "jwt-decode";

const CreateEvent = () => {
    const [isPro, setIsPro] = useState();
    const [state, setState] = useState(1);
    const [idUser, setIdUser] = useState();
    const [nameEvent, setNameEvent] = useState('nom de l\'évènement')
    const [isSelectedBoat, setIsSelectedBoat] = useState(null)
    const [boatName, setBoatName] = useState('')
    const [boatCapacity, setBoatCapacity] = useState('')
    const [isSelectedTime, setIsSelectedTime] = useState(false)
    const [horaire, setHoraire] = useState('')
    const [duration, setDuration] = useState('')
    const [selectDay, setSelectDay] = useState('')
    const [maxPers, setMaxPers] = useState(0);
    const [orgId, setOrgId] = useState()
    const [token, setToken] = useState(null)
    const [idEvent, setIdEvent] = useState()
    const [isPublic, setIsPublic] = useState(false)
    const [finalRequest, setFinalRequest] = useState({
        owner: null,
        organisateur: null,
        isPublic: null,
        name: null,
        eventName: null,
        boatId: null,
        startDate: null,
        duration: null,
    })

    useEffect(() => {
            setFinalRequest({
                ownerId: idUser,
                organizerId: orgId,
                isPublic: isPublic,
                eventName: nameEvent,
                boatId: isSelectedBoat,
                startDate: selectDay + 'T' + horaire + ':00',
                duration: duration
            });

            if(Platform.OS === 'web'){
                const getTokenWeb = () => {
                    const cookies = new Cookies;
                    setToken(cookies.get('jwtToken'))
                    if(token) {
                        const decodedToken = jwtDecode(token);
                        setIdUser(decodedToken.id)
                        setIsPro(decodedToken.isPro)
                        !decodedToken.isPro && state === 1 && setState(2)
                        // setState(decodedToken.isPro ? 1 : 2)
                    }
                }
                getTokenWeb()
            }else{
                const getValue = async () => { // Récupérer le token stocké dans le secureStore
                    try {
                        const value = await SecureStore.getItemAsync('jwtToken');
                        setToken(value)
                        if(value){
                            const jwtDecode = require('jwt-decode')
                            const decodedToken = jwtDecode(value)
                            setIdUser(decodedToken.id)
                            setIsPro(decodedToken.isPro)
                        }
                    } catch (error) {
                        console.log('Erreur lors de la récupération de la valeur:', error);
                    }
                };
                getValue();
            }
            if(state === 5){
                api.post(`events`, finalRequest, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then((res) => {
                        // console.log(res.data.id)
                        setIdEvent(res.data.id)

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
    }, [token, isPro, idUser, orgId, idUser, nameEvent, isSelectedBoat, selectDay, horaire, duration, state]);


  return(
      Platform.OS === 'web' ?
          <CreateEventWeb
              idUser={idUser}
              isPro={isPro}
              setState={setState}
              state={state}
              setNameEvent={setNameEvent}
              nameEvent={nameEvent}
              isSelectedBoat={isSelectedBoat}
              setIsSelectedBoat={setIsSelectedBoat}
              boatName={boatName}
              setBoatName={setBoatName}
              setBoatCapacity={setBoatCapacity}
              boatCapacity={boatCapacity}
              isSelectedTime={isSelectedTime}
              setIsSelectedTime={setIsSelectedTime}
              setHoraire={setHoraire}
              setDuration={setDuration}
              setSelectDay={setSelectDay}
              maxPers={maxPers}
              setMaxPers={setMaxPers}
              setOrgId={setOrgId}
              horaire={horaire}
              selectDay={selectDay}
              orgId={orgId}
              token={token}
              idEvent={idEvent}
              setIdEvent={setIdEvent}
              setIsPublic={setIsPublic}
              isPublic={isPublic}
          />
          :
          <CreateEventMobile
              idUser={idUser}
              isPro={isPro}
              setState={setState}
              state={state}
              setNameEvent={setNameEvent}
              nameEvent={nameEvent}
              isSelectedBoat={isSelectedBoat}
              setIsSelectedBoat={setIsSelectedBoat}
              boatName={boatName}
              setBoatName={setBoatName}
              isSelectedTime={isSelectedTime}
              setIsSelectedTime={setIsSelectedTime}
              setHoraire={setHoraire}
              setDuration={setDuration}
              setSelectDay={setSelectDay}
              maxPers={maxPers}
              setMaxPers={setMaxPers}
              setOrgId={setOrgId}
              horaire={horaire}
              selectDay={selectDay}
              orgId={orgId}
          />
  )
}

export default CreateEvent;
