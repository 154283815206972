import * as React from 'react';
import { Modal, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";

function ModalConfirmMail({onChange}) {

  const [showModal, setShowModal] = useState(true)

  const handleButtonClick = () => {
    const newState = !showModal;
    setShowModal(newState);
    onChange(newState);
  };


  const closeModal = () => {
    setShowModal(false)
  }

  return (

      <Modal
        transparent={true}
        visible={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <View style={styles.modalContainer}></View>
            <View style={styles.modalContent}>
              <Text style={{fontSize: 18, textTransform: 'uppercase', fontWeight: 'bold', color: '#2A2A48'}}>Confirmez votre email</Text>
              <View style={{marginVertical: 30, marginHorizontal: 20}}>
                <Text style={styles.textModal}>
                  Un email de confirmation a été envoyé à : <Text style={{fontWeight: 'bold'}}>unmail@contact.com</Text>
                </Text>
                <Text style={styles.textModal}>
                  Veuillez <Text style={{fontWeight: 'bold'}}>confirmer</Text> votre email en cliquant sur le lien, puis vous <Text style={{fontWeight: 'bold'}}>connecter</Text>.
                </Text>
                <Text style={styles.textModal}>
                  Si l'email n'apparaît pas dans votre réception, pensez à consulter vos <Text style={{fontWeight: 'bold'}}>spam</Text>.
                </Text>
                <Text style={styles.textModal}>
                  Si vous ne recevez pas l'email, contactez notre <Text style={{fontWeight: 'bold'}}>support</Text>
                </Text>
              </View>
              <TouchableOpacity style={styles.loginButton} onPress={() => handleButtonClick()}>
                <Text style={{fontSize: 16, color: '#2A2A48', fontWeight: 'bold'}}>Je me connecte </Text>
              </TouchableOpacity>
            </View>
      </Modal>
  );
}

const styles = StyleSheet.create({
  modalContent: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: 350,
    width: '80%',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
    paddingTop: 20,
    position: 'absolute',
    bottom: '25%',
    left: '10%'
  },
  modalContainer: {
    backgroundColor: '#2A2A48',
    opacity: 0.89,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  textModal: {
    color: '#2A2A48',
    fontSize: 12,
    marginVertical: 10
  },
  loginButton: {
    backgroundColor: '#E9E9EC',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  }
})

export default ModalConfirmMail;
