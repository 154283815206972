import * as React from 'react';
import {Dimensions, Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import { useState } from "react";
import {api} from "../../../config/axios";
import {COLORS} from "../../../constants/colors";


function ModalPassword({onChange, idUser, token, firstName, lastName, mail, phoneNumber }) {

  const [showModal, setShowModal] = useState(true)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleButtonClick = () => {
    const newState = !showModal;
    setShowModal(newState);
    onChange(newState);

    if(password !== newPassword && newPassword === confirmNewPassword){
      const updatePassword = {
        email: mail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          password: password,
          newPassword: newPassword,
          validNewPassword: confirmNewPassword
      }
      api.put(`users/${idUser}`,updatePassword, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
          .then((res) => {
            alert('Le nouveau mot de passe a bien été enregistré')
          })
          .catch((error) => {
            console.log('Erreur lors de la mise à jour', error);
            alert('Une erreur s\'est produite, veuillez réessayer')
          });
    }
  };

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <Modal
      transparent={true}
      visible={showModal}
      onRequestClose={() => {
        setShowModal(false);
      }}
      style={{alignItems: 'center'}}
    >
      <View style={styles.modalContainer}></View>
      <View style={styles.modalContent}>
        <Text style={{fontSize: 18, color: '#002759', paddingTop: 15}}>Réinitialiser votre mot de passe</Text>
        <View style={styles.formContainer}>
          <View style={styles.form}>
            <TextInput placeholderTextColor='#002759' style={styles.input} onChangeText={(val)=> setPassword(val)} placeholder='Saisissez votre mot de passe actuel'/>
          </View>
          <View style={styles.form}>
            <TextInput placeholderTextColor='#002759' style={styles.input} onChangeText={(val)=> setNewPassword(val)} placeholder='Saisissez le nouveau mot de passe' />
          </View>
          <View style={styles.form}>
            <TextInput placeholderTextColor='#002759' style={styles.input} onChangeText={(val)=> setConfirmNewPassword(val)} placeholder='Confirmez le nouveau mot de passe' />
          </View>
        </View>
        <View style={{flexDirection: 'row', width: Dimensions.get("window").width < 800 ? '100%' : '70%', alignItems: 'center', justifyContent: 'center'}}>
          <Pressable style={styles.editButton} onPress={handleButtonClick}>
            <Text style={{fontSize: 18, color: 'white', fontWeight: 'bold'}}>Confirmer</Text>
          </Pressable>
          <Pressable style={styles.refusedButton} onPress={handleButtonClick}>
            <Text style={{fontSize: 18, color: 'white', fontWeight: 'bold'}}>Annuler</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContent: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: 440,
    width: Dimensions.get("window").width < 800 ? '80%' : '30%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
    paddingTop: 20,
    position: 'absolute',
    top: '10%',
    left: '35%',
  },
  modalContainer: {
    backgroundColor: '#002759',
    opacity: 0.5,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  formContainer: {
    flex: 1,
    width: '85%',
    marginTop: 30
  },
  input: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#002759',
    borderWidth: 1,
    paddingVertical: 10,
    paddingLeft: 10,
    marginVertical: -5
  },
  form: {
    marginVertical: 20
  },
  editButton: {
    marginVertical: 30,
    paddingVertical: 15,
    width: '30%',
    backgroundColor: COLORS.opiomMainColor,
    alignItems: 'center'
  },
  refusedButton: {
    marginVertical: 30,
    marginLeft: 20,
    paddingVertical: 15,
    width: '30%',
    backgroundColor: 'red',
    alignItems: 'center'
  }
})

export default ModalPassword;
