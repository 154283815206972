import React, { useState } from 'react';
import {View, TextInput, Button, StyleSheet, Text, Modal, Pressable, Platform, Dimensions} from 'react-native';
import {COLORS} from "../../../constants/colors";
import {api} from "../../../config/axios";

const AddOrgModal = ({setOpenModal, idUser, token}) => {
  const [name, setName] = useState('');
  const [siret, setSiret] = useState('');
  const [representativeLastName, setRepresentativeLastName] = useState('');
  const [representativeFirstName, setRepresentativeFirstName] = useState('');
  const [representativeEmail, setRepresentativeEmail] = useState('');
  const [representativePhone, setRepresentativePhone] = useState('');

  const handleCancel = () => {

    setName('');
    setSiret('');
    setRepresentativeLastName('');
    setRepresentativeFirstName('');
    setRepresentativeEmail('');
    setRepresentativePhone('');

    setOpenModal(false)
  };
  const handleSubmit = () => {

    setName('');
    setSiret('');
    setRepresentativeLastName('');
    setRepresentativeFirstName('');
    setRepresentativeEmail('');
    setRepresentativePhone('');

    const orgInfos = {
        name: name,
        siret: siret,
        representativeFirstName: representativeFirstName,
        representativeLastName: representativeLastName,
        representativeMail: representativeEmail,
        representativePhoneNumber: representativePhone,
        ownerId: idUser
    }
    if(name && siret && representativeLastName && representativeFirstName && representativeEmail && representativePhone ){
      api.post( 'organizations', orgInfos, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
          .then(res => {
            console.log(res)
            setOpenModal(false)
          })
          .catch((error) => console.log(error))
    }
    console.log(orgInfos)
  };

  return (
      <Modal transparent={true} style={styles.container}>
        <View style={styles.modalContainer} />
        <View style={styles.modalContent}>
          <Text style={styles.title}>Ajout d'une organisation</Text>

          <View style={styles.fieldContainer}>
            <Text style={styles.label}>Nom de l'Organization:</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer le nom de l'organization"
                value={name}
                onChangeText={setName}
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.label}>SIRET:</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer le n°de SIRET"
                value={siret}
                onChangeText={setSiret}
                keyboardType="numeric"
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.label}>Prenom du représentant :</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer le prenom"
                value={representativeLastName}
                onChangeText={setRepresentativeLastName}
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.label}>Nom du représentant :</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer le nom"
                value={representativeFirstName}
                onChangeText={setRepresentativeFirstName}
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.label}>Email du représentant ::</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer l'Email"
                value={representativeEmail}
                onChangeText={setRepresentativeEmail}
                keyboardType="email-address"
            />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.label}>Representative's Phone:</Text>
            <TextInput
                style={styles.input}
                placeholder="Entrer le n° de telephone du représentant"
                value={representativePhone}
                onChangeText={setRepresentativePhone}
                keyboardType="phone-pad"
            />
          </View>

          <View style={styles.buttonContainer}>
            <Pressable style={{height: 50, width: 100, backgroundColor: "red", alignItems: 'center', justifyContent: 'center'}} onPress={handleCancel}>
              <Text style={{color: '#ffff'}}>Annuler</Text>
            </Pressable>
            <Pressable style={{height: 50, width: 100, backgroundColor: COLORS.opiomMainColor, alignItems: 'center', justifyContent: 'center', marginHorizontal: 10}} onPress={handleSubmit}>
              <Text style={{color: '#ffff'}}>Créer</Text>
            </Pressable>
          </View>
        </View>

      </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  modalContent: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    minHeight: Dimensions.get("window").width > 650 ? 550 : 500,
    width: Platform.OS === 'web' && Dimensions.get("window").width > 650  ? '50%' : 300,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
    paddingTop: 20,
    position: 'absolute',
    top: '10%',
    // left: '10%',
  },
  modalContainer: {
    backgroundColor: '#002759',
    opacity: 0.5,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
  },
  fieldContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    width: Platform.OS !== 'web' && '80%'
  },
  label: {
    fontWeight: 'bold',
    width: 200,
    display: Platform.OS !== 'web' || Dimensions.get("window").width < 650 && 'none',
  },
  input: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',

  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 16,
  },
});

export default AddOrgModal;