import * as React from 'react';
import { Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import ModalConfirmEventWeb from "./ModalConfirmEventWeb";
import {COLORS} from "../../../../constants/colors";


function ModalInvitationWeb({ data, setModal } ) {
    const [showModal, setShowModal] = useState(true)
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    return (
        <Modal
            transparent={true}
            visible={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
        >
            <View style={styles.modalContainer}></View>
            <View style={styles.modalContent}>
                <View style={styles.formContainer}>
                    <View>
                        <Text style={{fontSize: 40, color: '#002759', opacity: 0.2}}>{data.eventName}</Text>
                        <Text style={{fontSize: 26, color: '#002759', opacity: 0.2}}>{data.orgName}</Text>
                    </View>
                    <View style={{alignSelf: 'center', marginTop: 25, width: 300, alignItems: 'flex-start'}}>
                        {
                            <View style={styles.card}>
                                <Text style={styles.textInfos}>{data.date}</Text>
                                <Text style={styles.textInfos}>{data.heure}</Text>
                                <Text style={styles.textInfos}>{data.bateau}</Text>
                                {
                                    data.tarif !== 'undefined' && <Text style={styles.textInfos}>{data.tarif}</Text>
                                }
                            </View>
                        }
                    </View>
                </View>
                <View style={{flexDirection: 'row', width: 400, justifyContent: 'space-around'}}>
                    <Pressable style={styles.declineButton} onPress={() => setModal(-1)}>
                        <Text style={{fontSize: 15, color: COLORS.opiomMainColor, fontWeight: 'bold'}}>Décliner</Text>
                    </Pressable>
                    <Pressable style={styles.acceptButton} onPress={() => setModal(2)}>
                        <Text style={{fontSize: 15, color: 'white', fontWeight: 'bold'}}>{data.tarif ? 'Payer' : 'accepter'}</Text>
                    </Pressable>
                    {
                       openConfirmModal && <ModalConfirmEventWeb data={data} />
                    }
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalContent: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        width: 650,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 2,
        paddingTop: 20,
        position: 'absolute',
        top: 200,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    modalContainer: {
        backgroundColor: '#002759',
        opacity: 0.5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    formContainer: {
        flex: 1,
        width: '85%',
        marginTop: 30
    },
    card: {
        alignItems: 'flex-start',
        marginVertical: 20
    },
    acceptButton: {
        marginTop: 40,
        marginBottom: 70,
        paddingVertical: 15,
        width: 150,
        backgroundColor: COLORS.opiomMainColor,
        alignItems: 'center'
    },
    textInfos: {
        textAlign: 'left',
        paddingVertical: 10,
        color: '#333333',
        fontSize: 15,
        fontWeight: '600'
    },
    declineButton: {
        marginTop: 40,
        marginBottom: 70,
        paddingVertical: 15,
        width: 150,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: COLORS.opiomMainColor,
        alignItems: 'center'
    },
})

export default ModalInvitationWeb;
