import * as React from 'react';
import {Text, View, StyleSheet, TextInput, Pressable, Image, Easing, Dimensions} from "react-native";
import {useState} from "react";
import {api} from "../../../config/axios";
import Cookies from "universal-cookie/es6";
import {Link, useNavigate} from "react-router-dom"
import Header from "./Header";
import {useNavigation} from "@react-navigation/native";


function LoginWeb() {
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [show, setShow] = useState(true)
    const [logError, setLogError] = useState(false)



    const userLogin = (e) => {
        let userData = {
            email: mail,
            password: password,
        }
        api.post(`auth/login`, userData)
            .then((res) => {
                const cookies = new Cookies();
                cookies.set('jwtToken', res.data.access_token); // ne pas oublier d'inclure { secure: true, httpOnly: true } lorsqu'on aura une connexion HTTPS
                if(res.data.access_token){
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log('Une erreur s\'est produite lors de la connexion :', error);
                setLogError(true)
            });
    }

    return (
        <View
            style={{
                backgroundColor: '#EDEDED',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                // position: 'absolute',
                // top: 0
            }}>

                <Header />

            <View style={styles.formContainer}>
                <Text style={styles.title}>me connecter</Text>
                <View>
                    <Text style={styles.label}>Email</Text>
                    <TextInput style={styles.input} onChangeText={(newValue) => setMail(newValue)} />
                </View>

                <View style={styles.labelInput}>
                    <Text style={styles.label}>Password</Text>
                    <TextInput style={styles.input} secureTextEntry={show} autoCorrect={false}  onChangeText={(newValue) => setPassword(newValue)} placeholder='Doit contenir 6 caractères min.' />
                    <Pressable onPress={() => setShow(!show)}>
                        <Image style={styles.hideButton} source={require('../../../../assets/images/Show-Hide.png')}/>
                    </Pressable>
                </View>

                {
                    logError &&
                    <View style={{padding: 20, backgroundColor: 'red', marginTop: 30}}>
                        <Text style={{color: '#ffff'}}>Email ou mot de passe incorrect. Veuillez réessayer.</Text>
                    </View>
                }

                {/*<View style={{marginTop: 20, flexDirection: 'row', alignItems: 'center'}}>*/}
                {/*    <Text style={{fontWeight: 'bold', marginLeft: 15, fontSize: 10}}>*/}
                {/*        Se souvenir pour la prochaine fois*/}
                {/*    </Text>*/}
                {/*</View>*/}

                <View style={styles.button}>
                    <Pressable style={({ pressed }) => [
                        {
                            backgroundColor: pressed ? '#63A645' : '#D9D9DC',
                        },
                    ]} onPress={(e) => userLogin(e)}>
                        <Text style={{padding: 15, fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>Se connecter</Text>
                    </Pressable>
                </View>
                <View style={{alignItems: 'center', marginTop: 30}}>
                    <Text style={{fontWeight: 'bold'}}>Pas encore de compte ?</Text>
                    <Pressable>
                        <Link to='/register' style={{textDecoration: 'none', textAlign: 'center'}}>
                            <Text>Inscrivez-vous dès maintenant !</Text>
                        </Link>
                    </Pressable>
                </View>
            </View>
        </View>
    );
}

// @ts-ignore
const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 25,
        textTransform: 'uppercase',
    },
    label: {
        margin: 10,
        marginLeft: 0,
    },
    labelInput: {
        marginTop: 20,
    },
    buttonLogin: {
        color: 'black',
    },
    button: {
        marginTop: 20,
        backgroundColor: '#D9D9DC',
        borderRadius: 4,
        color: 'white',
    },
    formContainer: {
        width: Dimensions.get("window").width > 950 ?  '30%' : '80%',
    },
    container: {
        padding: 8,
        alignItems: 'center',
        backgroundColor: '#EDEDED',
    },
    input: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderColor: '#BFBFC8',
        borderWidth: 1,
        padding: 10,
        borderRadius: 10,
    },
    login: {
        textAlign: 'center',
        marginTop: 50,
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
    },
    hideButton: {
        position: 'absolute',
        right: 10,
        bottom: 10,
        width: 20,
        height: 20
    },
});

export default LoginWeb;
