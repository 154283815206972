import React, {useEffect, useState} from 'react';
import { SafeAreaView, ScrollView, View, Text, Image, Pressable, StyleSheet } from "react-native";
import HeaderScreen from "../Mobile/Components/HeaderScreen";
export default function UserProfil({ data }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigation = useNavigation();


  useEffect(() => {
    setFirstName(data.firstName)
    setLastName(data.lastName)
    setEmail(data.email)
    setPhoneNumber(data.phoneNumber)
  })

  return(
      // <ScrollView>
      <>
        <SafeAreaView>
          <HeaderScreen />
        </SafeAreaView>
        <View style={styles.container}>
          <View style={styles.topContent}>
            <View style={styles.image}>
              <Image style={{width: 50, height: 50, borderRadius: 50}} resizeMode='contain' source={require('../../assets/images/people-fill2x.png')} />
            </View>
            <View style={{alignItems: 'center', marginTop: 25}}>
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Text style={styles.name}>{`${firstName} ${lastName}`}</Text>
                <Pressable onPress={() => navigation.navigate('ProfilForm', {
                  data: data
                })}>
                  <Image style={{width: 20, height: 20}} resizeMode='contain' source={require('../../assets/images/settings2x.png')} />
                </Pressable>
              </View>
              <Text style={styles.mail}>{email}</Text>
            </View>
          </View>

          <View style={styles.qrCode}>
            <Text style={{fontSize: 10, textAlign: 'center', opacity: 0.5}}>QR CODE {'\n'} (arrive prochainement)</Text>
          </View>

          <View style={styles.solde}>
            <Text>Votre solde est de : x €</Text>
            <Pressable style={styles.btnCrediter}>
              <View style={{width: 15, height: 15, backgroundColor: '#ffff', alignItems: 'center', justifyContent: 'center', borderRadius: 50}}>
                <Image style={{width: 25, height: 25, marginBottom: 5}} resizeMode='center' source={require('../../assets/images/plus2x.png')} />
              </View>
              <Text style={{color: '#ffff', marginLeft: 10}}>Créditer</Text>
            </Pressable>
          </View>
        </View>

        {/*<View style={{minHeight: '100%', paddingVertical: 50, paddingHorizontal: 40, backgroundColor: 'white'}}>*/}
        {/*  <ProfilForm />*/}
        {/*</View>*/}
        {/*</ScrollView>*/}
      </>

)
}
import ProfilForm from "../Mobile/Components/Profil/ProfilForm";

import {COLORS} from "../constants/colors";
import {useNavigation} from "@react-navigation/native";

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#ffff',
    height: '100%',
    width: '100%'
  },
  topContent: {
    marginTop: 50,
    alignItems: 'center',
    justifyContent: 'center'
  },
  name: {
    fontSize: 20,
    fontWeight: '500',
    color: COLORS.textColorOpiom,
    marginHorizontal: 10
  },
  mail: {
    color: COLORS.textColorOpiom
  },
  qrCode: {
    marginTop: 100,
    backgroundColor: 'white',
    height: 144,
    width: 144,
    borderStyle: 'solid',
    borderWidth: 12,
    borderColor: COLORS.gris,
    justifyContent: 'center'
  },
  solde: {
    marginTop: 50,
    alignItems: 'center'
  },
  btnCrediter: {
    flexDirection: 'row',
    marginTop: 20,
    backgroundColor: COLORS.opiomMainColor,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 100
  }
})
