import {Pressable, StyleSheet, Text, View} from "react-native";
import {Hoverable} from "react-native-web-hover";
import {useState} from "react";
import ModalLastEventsDetailsWeb from "./Modals/ModalLastEventsDetailsWeb";
import Pagination from "./pagination/Pagination";
import {COLORS} from "../../../constants/colors";

const ConsoUserTabWeb = () => {

    // const [tableData, setTableData] = useState([
    //     {id: 1, date: '01/03/2023', heure: '20H13', productConsumed: 'Biere', price: 5},
    //     {id: 2, date: '10/03/2023', heure: '21H34', productConsumed: 'Coca', price: 7},
    //     {id: 3, date: '14/03/2023', heure: '22H45', productConsumed: 'Martini', price: 8},
    //     {id: 4, date: '10/04/2023', heure: '22H57', productConsumed: 'Pression', price: 6},
    // ]);
    const [filterText, setFilterText] = useState('');
    const [sortOrder, setSortOrder] = useState({ column: 'name', order: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);

    // const filteredData = tableData.filter((item) =>
    //     item.date.toLowerCase().includes(filterText.toLowerCase()) ||
    //     item.productConsumed.toLowerCase().includes(filterText.toLowerCase()) ||
    //     item.price.toString().includes(filterText)
    // );


    // const handleSort = (column) => {
    //     const sortedData = tableData.slice().sort((a, b) => {
    //         if (sortOrder.order === 'asc') {
    //             return a[column].localeCompare(b[column]);
    //         } else {
    //             return b[column].localeCompare(a[column]);
    //         }
    //     });
    //     setTableData(sortedData);
    //     setSortOrder({ column: column, order: sortOrder.order === 'asc' ? 'desc' : 'asc' });
    // };


    return(
        <View style={styles.container}>
            <View style={styles.headerTab}>
                <View style={styles.headerTabContent}>
                    <Text style={{fontSize: 22, color: COLORS.opiomMainColor}}>
                        Liste des consommations
                    </Text>
                </View>
            </View>

            <View  style={{alignItems: 'center', width: '100%'}}>
                <View style={[styles.row, {backgroundColor: '#F3F3F3'}]}>
                    <Pressable style={styles.cell} onPress={() => handleSort('date')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'date' ? (sortOrder.order === 'asc' ? 'Date ▲' : 'Date ▼') : 'Date'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('productConsumed')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'productConsumed' ? (sortOrder.order === 'asc' ? 'Produit(s) consommé(s) ▲' : 'Produit(s) consommé(s) ▼') : 'Produit(s) consommé(s)'}</Text>
                    </Pressable>
                    <Pressable style={styles.cell} onPress={() => handleSort('price')}>
                        <Text style={styles.nameCol}>{sortOrder.column === 'price' ? (sortOrder.order === 'asc' ? 'Price ▲' : 'Price ▼') : 'Montant'}</Text>
                    </Pressable>

                </View>
                {/*{*/}
                {/*    filteredData.map((item, key) => (*/}
                {/*        <Hoverable key={key} style={[key %2 ==0 ? styles.white : styles.backgroundRow, {width: '95%'}]}>*/}
                {/*            <View style={{width: '100%', flexDirection: 'row', paddingLeft: 25, paddingVertical: 10}}>*/}
                {/*                <Text style={styles.cell}>{`${item.date} - ${item.heure}`}</Text>*/}
                {/*                <Text style={styles.cell}>{item.productConsumed}</Text>*/}
                {/*                <Text style={styles.cell}>{`${item.price} €`}</Text>*/}
                {/*            </View>*/}
                {/*        </Hoverable>*/}
                {/*    ))*/}
                {/*}*/}
                <Pagination  />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: '65%',
        flexDirection: "column",
        marginLeft: '10%'
    },
    headerTab: {
        width: '100%',
        marginBottom: 50
    },
    headerTabContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '85%',
        marginLeft: 30,
        alignItems: 'center'
    },
    buttonHeaderTab: {
        marginHorizontal: 10,
        backgroundColor: '#EEEEEE',
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    textButton: {
        fontSize: 13,
        color: '#0F1C2A',
        fontWeight: 'bold'
    },
    white: {
        backgroundColor: 'white'
    },
    backgroundRow: {
        backgroundColor: '#F3F3F3',
    },
    cell: {
        flex: 1,
        textAlign: 'left',
        marginLeft: 40
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingLeft: 25,
        width: '95%',
    },
    nameCol: {
        fontSize: 13,
        fontWeight: 'bold'
    },
    rowHovered: {
        filter: 'blur(1.5px)',
    },
    buttonTab: {
        flexDirection: 'row',
        position: 'absolute',
        left: '22%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999
    },
    buttonG: {
        backgroundColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    buttonD: {
        backgroundColor: '#0B1B2B',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginLeft: 10
    },
    hoveredButtonG: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
    hoveredButtonD: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D3414A',
        paddingHorizontal: 15,
        paddingVertical: 3,
        marginRight: 10
    },
})

export default ConsoUserTabWeb;
