import React, {useEffect, useRef, useState} from "react";
import {Image, Pressable, SafeAreaView, StyleSheet, Text, TextInput, View} from "react-native";
import ModalPassword from "./ModalPassword";
import {COLORS} from "../../../constants/colors";
import Header from "../HeaderScreen";
import * as SecureStore from "expo-secure-store";
import {api} from "../../../config/axios";
import Cookies from "universal-cookie/es6";

const ProfilForm = ( { navigation, route } ) => {
  const {data} = route.params
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const [birthDate, setBirthDate] = useState('27/08/2000');
  const [disabled, setDisabled] = useState(false);
  const isDisabledRef = useRef(disabled)
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState('');
  const [idUser, setIdUser] = useState();
  const [updateButton, setUpdateButton] = useState(null)

  useEffect(() => {
    const getValue = async () => { // Récupérer le token stocké dans le secureStore
      try {
        const value = await SecureStore.getItemAsync('jwtToken');
        setToken(value)
        if(token){
          const jwtDecode = require('jwt-decode')
          const decodedToken = jwtDecode(value)
          setIdUser(decodedToken.id)

        }
      } catch (error) {
        console.log('Erreur lors de la récupération de la valeur:', error);
      }
    };
    getValue();
  }, [token, idUser])

  useEffect(() => {
    if(disabled === false){
      setLastName(data.lastName)
      setFirstName(data.firstName)
      setPhoneNumber(data.phoneNumber)
      setMail(data.email)
    }
  })
  const editProfil = () => {
    setDisabled(!disabled);
    setFirstName(data.firstName)
    setLastName(data.lastName)
    setPhoneNumber(data.phoneNumber)
    setMail(data.email)
    updateInfos()
  }

  useEffect(() => {
    if (isDisabledRef.current && !disabled) {
      setUpdateButton(true)
    }

    isDisabledRef.current = disabled;
  }, [disabled]);

  const handleFirstNameChange = (value) => {
    setFirstName(value); // Mettez à jour la valeur du prénom de l'utilisateur dans l'état
  };

  const handleLastNameChange = (value) => {
    setLastName(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
  };

  const handleMailChange = (value) => {
    setMail(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
  };


  const updateInfos = () => {
    if(updateButton === true && firstName !== data.firstName || lastName !== data.lastName || phoneNumber !== data.phoneNumber || mail !== data.email){
      const updateUser = {
        lastName: lastName,
        firstName: firstName,
        phoneNumber: phoneNumber,
        email: mail,
      }

      api.put(`users/${data.id}`,updateUser, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
          .then((res) => {
            if(mail !== data.email){
              const cookies = new Cookies;
              cookies.set( 'jwtToken', res.data.jwtToken)
            }
          })
          .catch((error) => {
            console.log('Erreur lors de la mise à jour', error);
          });
    }
  }

  const pressModal = () => {
    setModal(true)
  }

  const handleChange = (newState) => {
    setModal(newState)
  }

  return (
    <>
      <SafeAreaView>
        <Header />
      </SafeAreaView>
      <View style={styles.container}>

        <Pressable style={{marginLeft: 30, marginTop: 20}} onPress={() => navigation.goBack()}>
          <Text>Retour</Text>
        </Pressable>

        <View style={{alignSelf: 'center', marginVertical: 30}}>
          <View style={styles.image}>
            <Image style={{width: 50, height: 50, borderRadius: 50}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
          </View>
        </View>

        <View style={styles.formContainer}>
          <View style={styles.form}>
            <TextInput style={styles.input} editable={disabled} value={firstName} onChangeText={handleFirstNameChange}/>
          </View>
          <View style={styles.form}>
            <TextInput style={styles.input} editable={disabled} value={lastName} onChangeText={handleLastNameChange} />
          </View>
          <View style={styles.form}>
            <TextInput style={styles.input} editable={disabled} value={mail} onChangeText={handleMailChange} />
          </View>
          <View style={styles.form}>
            <TextInput style={styles.input} editable={disabled} value={phoneNumber} onChangeText={handlePhoneNumberChange} />
          </View>
          <View style={styles.form}>
            <TextInput style={styles.input} editable={disabled} value={birthDate} onChangeText={(val)=> setBirthDate(val)}  />
          </View>
          <Pressable style={styles.changePassword} onPress={pressModal}>
            <Text style={{fontSize: 18, color: '#002759'}}>Modifier son mot de passe</Text>
          </Pressable>
          {modal && <ModalPassword onChange={handleChange} idUser={idUser} token={token} firstName={firstName} lastName={lastName} mail={mail} phoneNumber={phoneNumber} />}
          {
            disabled ? (
              <Pressable style={styles.saveButton} onPress={editProfil}>
                <Text style={{fontSize: 18, color: '#002759'}}>Enregistrer les modifications</Text>
              </Pressable>
            ) : (
              <Pressable style={styles.editButton} onPress={editProfil}>
                <Text style={{fontSize: 18, color: 'white'}}>Éditer son profil</Text>
              </Pressable>
            )
          }
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffff'
  },
  formContainer: {
    // flex: 1,
    width: '90%',
    alignSelf: 'center',
    // marginTop: 60
  },
  label: {
    marginBottom: 15,
    marginLeft: 20,
    color: '#002759',
    fontSize: 18
  },
  input: {
    backgroundColor: COLORS.gris,
    paddingHorizontal: 15,
    height: 40
  },
  form: {
    marginVertical: 10
  },
  editButton: {
    marginVertical: 20,
    paddingVertical: 15,
    width: '100%',
    backgroundColor: COLORS.opiomMainColor,
    alignItems: 'center'
  },
  saveButton: {
    marginVertical: 20,
    paddingVertical: 15,
    width: '100%',
    backgroundColor: COLORS.gris,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#002759',
    alignItems: 'center',
  },
  changePassword: {
    marginVertical: 10,
    width: '100%',
    alignItems: 'center'
  }
})

export default ProfilForm;
