import {createNativeStackNavigator} from "@react-navigation/native-stack";
import identification from "../Identification/Identification";
import login from "../Components/Identification/LoginMobile";
import register from "../Components/Identification/RegisterMobile";
import {NavigationContainer} from "@react-navigation/native";

const Stack = createNativeStackNavigator();
const AuthStack = () => {
  return(
      <NavigationContainer>
          <Stack.Navigator initialRouteName="Identification" screenOptions={{ headerShown: false }}>
              <Stack.Screen name="Identification" component={identification} />
              <Stack.Screen name="Login" component={login} />
              <Stack.Screen name="Register" component={register} />
          </Stack.Navigator>
      </NavigationContainer>
  )
}

export default AuthStack;