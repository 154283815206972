import React, {useState} from 'react';
import {Image, StyleSheet, Text, View, Platform, ScrollView, Dimensions, Pressable} from "react-native";
import {COLORS} from "../../../constants/colors";
import AddOrgModal from "../../../Web/Component/CreateEventComponents/AddOrgModal";

function ChoiceOrgMobile( { setOrgId, data }  ) {
    const [isSelected, setIsSelected] = useState(null)
    const [isEventSelected, setIsEventSelected] = useState(null)
    const [openModal, setOpenModal] = useState(false)


    const handlePress = (id) => {
        if(isSelected === id) {
            setIsSelected(null)
            setIsEventSelected(null)
        }else{
            setIsSelected(id)
        }
    };
    if(isSelected){
        setOrgId(isSelected)
    }

    const handleEvent = (id) => {
        setIsEventSelected(id)
        if(isEventSelected === id) {
            setIsEventSelected(null)
        }else {
            setIsEventSelected(id)
        }
    }
    return(
            <View style={styles.content}>
                <Text style={styles.title}>À quel nom organisez-vous l'évènement</Text>
                <View style={styles.orgContainer}>
                    <ScrollView contentContainerStyle={{alignItems: 'center'}}>
                        <Pressable style={isSelected === 0 ? styles.selectedCard : styles.notSelectedCard} onPress={() => handlePress(0)}>
                            <View style={styles.imageBateau}>
                                <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                            </View>
                            <View style={{width: '80%', alignSelf: 'center'}}>
                                <Text style={[{marginTop: 5, marginBottom: 5, color: isSelected === 0 ? 'white' : COLORS.opiomMainColor}]}>Moi même</Text>
                            </View>
                        </Pressable>
                        {
                            data?.map((item) => (
                                <Pressable key={item.id} style={isSelected === item.id ? styles.selectedCard : styles.notSelectedCard} onPress={() => handlePress(item.id)}>
                                    <View style={styles.imageBateau}>
                                        <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                                    </View>
                                    <View style={{width: '80%', alignSelf: 'center'}}>
                                        <Text style={[{marginTop: 5, marginBottom: 5, color: isSelected === item.id ? 'white' : COLORS.opiomMainColor}]}>{item.name}</Text>
                                    </View>
                                </Pressable>
                            ))
                        }
                        <Pressable style={[styles.card, {backgroundColor: COLORS.gris, borderColor: COLORS.gris, marginTop: 20}]} onPress={() => setOpenModal(true)}>
                            <Text style={{textAlign: 'center', padding: 10}}>Ajouter une organisation</Text>
                        </Pressable>
                    </ScrollView>
                    {
                        openModal &&
                            <AddOrgModal setOpenModal={setOpenModal} />
                    }
                    <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-around', marginTop: 20}}>
                        <Pressable style={isEventSelected === 1 ? styles.buttonSelected : styles.buttonNotSelected} onPress={() => handleEvent(1)}>
                            <Text style={isEventSelected === 1 ? styles.textButtonSelected : styles.textButtonNotSelected}>Public</Text>
                        </Pressable>
                        <Pressable style={isEventSelected === 2 ? styles.buttonSelected : styles.buttonNotSelected} onPress={() => handleEvent(2)}>
                            <Text style={isEventSelected === 2 ? styles.textButtonSelected : styles.textButtonNotSelected}>Privé</Text>
                        </Pressable>
                    </View>
                </View>
            </View>
    )
}


const styles = StyleSheet.create({
    content: {
        // height: Dimensions.get('window').height,
        width: '100%',
        height: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        // marginTop: 50,
        // backgroundColor: 'blue',
    },
    title: {
        fontSize: 20,
        textAlign: 'center'
    },
    orgContainer: {
        alignItems: 'center',
        height: '80%',
        width: '80%',
        // backgroundColor: 'red'
    },
    cardContainer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        marginTop: 20
    },
    // card: {
    //     height: 130,
    //     width: 130,
    //     borderStyle: 'solid',
    //     borderWidth: 10,
    //     borderRadius: 10,
    //     marginHorizontal: 30,
    //     marginTop: 30,
    //     justifyContent: 'center',
    //     alignItems: 'center',
    // },
    notSelectedCard: {
        backgroundColor: 'white',
        // height: 175,
        width: 150,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginHorizontal: 30,
        justifyContent: 'flex-end',
        // marginBottom: 30,
        marginTop: 30,
        alignItems: 'center',
    },
    selectedCard: {
        width: 150,
        // marginRight: 30,
        // marginBottom: 30,
        marginTop: 30,
        borderRadius: 10,
        backgroundColor: COLORS.opiomMainColor
    },
    imageBateau: {
        backgroundColor: COLORS.gris,
        width: '80%',
        height: 90,
        borderRadius: 10,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 15
    },
    buttonSelected: {
        width: 150,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderBottomColor: COLORS.opiomMainColor,
        backgroundColor: COLORS.opiomMainColor,
        borderRadius: 5
    },
    buttonNotSelected: {
        width: 150,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderBottomColor: COLORS.opiomMainColor,
        borderRadius: 5
    },
    textButtonSelected: {
        fontWeight: "600",
        color: 'white'
    },
    textButtonNotSelected: {
        fontWeight: "600",
    }
})

export default ChoiceOrgMobile
