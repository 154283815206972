import React from 'react';
import { View, Image, StyleSheet, Dimensions, SafeAreaView } from "react-native";

function Header() {
  return (
    <View style={{alignItems: 'center'}}>
      <View style={styles.header}>
        <Image style={styles.circleHeader} resizeMode='stretch' source={require('../../../../assets/images/arc-cercle.png')} />
        <View style={styles.logo}>
          <Image source={require('../../../../assets/images/logo-opiom.png')} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logo: {
    position: 'absolute',
    alignSelf: 'center',
    top: 70
  },
  header: {
    position: 'relative',
  },
  circleHeader: {
    width: Dimensions.get('window').width,
    // width: '100%',
    minHeight: 200,
  },
});
export default Header;
