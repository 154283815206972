import React, {useEffect, useRef, useState} from "react";
import {Dimensions, Platform, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import ModalPassword from "../../../Mobile/Components/Profil/ModalPassword";
import {COLORS} from "../../../constants/colors";
import {api} from "../../../config/axios";
import Cookies from "universal-cookie/es6";
import jwtDecode from "jwt-decode";

const ProfilContentWeb = ( { data, token, idUser } ) => {
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [mail, setMail] = useState();
    const [birthDate, setBirthDate] = useState('27/08/2000');
    const [disabled, setDisabled] = useState(false);
    const isDisabledRef = useRef(disabled)
    const [modal, setModal] = useState(false);
    const [updateButton, setUpdateButton] = useState(null)


    useEffect(() => {
        if(disabled === false){
            setLastName(data.lastName)
            setFirstName(data.firstName)
            setPhoneNumber(data.phoneNumber)
            setMail(data.email)
        }
    })


    const handleFirstNameChange = (value) => {
        setFirstName(value); // Mettez à jour la valeur du prénom de l'utilisateur dans l'état
    };

    const handleLastNameChange = (value) => {
        setLastName(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
    };

    const handleMailChange = (value) => {
        setMail(value); // Mettez à jour la valeur du nom de famille de l'utilisateur dans l'état
    };

    const getToken = () => {
        const cookies = new Cookies;
        return cookies.get('jwtToken')
    }

    const WIDTH = Dimensions.get('window').width

    const styles = StyleSheet.create({
        formContainer: {
            alignSelf:'center',
            // backgroundColor: 'red',
          marginLeft: Dimensions.get("window").width > 1150 ? '20%' : '5%'
        },
        text: {
            color: '#333333',
            fontSize: 30,
            marginBottom: 30
        },
        content: {
            flexDirection: 'row',
        },
        label: {
            marginBottom: 15,
            color: '#333333',
            fontSize: 18
        },
        input: {
            backgroundColor: 'white',
            borderStyle: 'solid',
            borderColor: '#002759',
            borderWidth: 1,
            padding: 15,
            opacity: disabled ? 1 : 0.3
        },
        form: {
            marginVertical: 20,
            width: 235
        },
        editButton: {
            marginVertical: 20,
            paddingVertical: 15,
            width: 300,
            backgroundColor: COLORS.opiomMainColor,
            alignItems: 'center',
            alignSelf: 'center'
        },
        card: {
            backgroundColor: 'white',
            height: 144,
            width: 144,
            borderStyle: 'solid',
            borderWidth: 12,
            borderColor: COLORS.gris
        },
        saveButton: {
            marginVertical: 20,
            paddingVertical: 15,
            width: 300,
            backgroundColor: COLORS.gris,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#002759',
            alignItems: 'center',
            alignSelf: 'center'
        },
        changePassword: {
            marginTop: 60,
            width: '100%',
            alignItems: 'center',
        }
    })

    const editProfil = () => {
        setDisabled(!disabled);
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setPhoneNumber(data.phoneNumber)
        setMail(data.email)
        updateInfos()
    }

    useEffect(() => {
        if (isDisabledRef.current && !disabled) {
            setUpdateButton(true)
            console.log("L'état est passé de true à false");
        }

        isDisabledRef.current = disabled;
    }, [disabled]);


    const updateInfos = () => {
        if(updateButton === true && firstName !== data.firstName || lastName !== data.lastName || phoneNumber !== data.phoneNumber || mail !== data.email){
            const updateUser = {
                lastName: lastName,
                firstName: firstName,
                phoneNumber: phoneNumber,
                email: mail,
            }

            let token = getToken()

            api.put(`users/${data.id}`,updateUser, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => {
                    if(mail !== data.email){
                        const cookies = new Cookies;
                        cookies.set( 'jwtToken', res.data.jwtToken)
                    }
                })
                .catch((error) => {
                    console.log('Erreur lors de la mise à jour', error);
                });
        }
    }

    const pressModal = () => {
        setModal(true)
    }

    const handleChange = (newState) => {
        setModal(newState)
    }

    return (
        <View style={styles.formContainer}>
            <View style={styles.content}>
                <View style={{marginRight: 30, flexDirection: 'row'}}>
                    <View>
                        <Text style={styles.text}>Profil</Text>
                        {
                            Dimensions.get("window").width > 780 ?
                            <View style={{flexDirection: 'row'}}>
                                <View style={{marginRight: 50}}>
                                    <View style={styles.form}>
                                        <Text style={styles.label}>Prénom</Text>
                                        <TextInput style={styles.input} editable={disabled} value={firstName} onChangeText={handleFirstNameChange}/>
                                    </View>
                                    <View style={styles.form}>
                                        <Text style={styles.label}>Téléphone</Text>
                                        <TextInput style={styles.input} editable={disabled} value={phoneNumber} onChangeText={handlePhoneNumberChange} />
                                    </View>
                                    <View style={styles.form}>
                                        <Text style={styles.label}>Adresse Mail</Text>
                                        <TextInput style={styles.input} editable={disabled} value={mail} onChangeText={handleMailChange} />
                                    </View>
                                </View>
                                <View>
                                    <View style={styles.form}>
                                        <Text style={styles.label}>Nom</Text>
                                        <TextInput style={styles.input} editable={disabled} value={lastName} onChangeText={handleLastNameChange} />
                                    </View>
                                    <View style={styles.form}>
                                        <Text style={styles.label}>Date de naissance</Text>
                                        <TextInput style={styles.input} editable={disabled} value={birthDate} onChangeText={(val)=> setBirthDate(val)}  />
                                    </View>
                                    <Pressable style={styles.changePassword} onPress={pressModal}>
                                        <Text style={{fontSize: 18, color: '#333333'}}>Modifier son mot de passe</Text>
                                    </Pressable>
                                </View>
                            </View>
                                :
                                <View>
                                    <View style={{marginRight: 50}}>
                                        <View style={styles.form}>
                                            <Text style={styles.label}>Prénom</Text>
                                            <TextInput style={styles.input} editable={disabled} value={firstName} onChangeText={handleFirstNameChange}/>
                                        </View>
                                        <View style={styles.form}>
                                            <Text style={styles.label}>Nom</Text>
                                            <TextInput style={styles.input} editable={disabled} value={lastName} onChangeText={handleLastNameChange} />
                                        </View>
                                        <View style={styles.form}>
                                            <Text style={styles.label}>Téléphone</Text>
                                            <TextInput style={styles.input} editable={disabled} value={phoneNumber} onChangeText={handlePhoneNumberChange} />
                                        </View>
                                        <View style={styles.form}>
                                            <Text style={styles.label}>Adresse Mail</Text>
                                            <TextInput style={styles.input} editable={disabled} value={mail} onChangeText={handleMailChange} />
                                        </View>
                                    </View>
                                    <View>
                                        <View style={styles.form}>
                                            <Text style={styles.label}>Date de naissance</Text>
                                            <TextInput style={styles.input} editable={disabled} value={birthDate} onChangeText={(val)=> setBirthDate(val)}  />
                                        </View>
                                        <Pressable style={styles.changePassword} onPress={pressModal}>
                                            <Text style={{fontSize: 18, color: '#333333'}}>Modifier son mot de passe</Text>
                                        </Pressable>
                                    </View>
                                </View>

                        }
                    </View>
                </View>
            </View>
            {modal && <ModalPassword onChange={handleChange} idUser={idUser} token={token} firstName={firstName} lastName={lastName} mail={mail} phoneNumber={phoneNumber} /> }
            {
                disabled ? (
                    <Pressable style={styles.saveButton} onPress={editProfil}>
                        <Text style={{fontSize: 18, color: '#333333'}}>Enregistrer les modifications</Text>
                    </Pressable>
                ) : (
                    <Pressable style={styles.editButton} onPress={editProfil}>
                        <Text style={{fontSize: 18, color: 'white'}}>Éditer son profil</Text>
                    </Pressable>
                )
            }

        </View>
    )
}


export default ProfilContentWeb;
