import {Text, StyleSheet, View, Pressable, Platform} from "react-native";
import {useEffect, useState} from "react";
import ChoiceBoatWeb from "../../Web/Component/CreateEventComponents/ChoiceBoatWeb";
import Cookies from "universal-cookie/es6";
import {api} from "../../config/axios";
import ChoiceBoatMobile from "../../Mobile/Components/CreateEventComponents/ChoiceBoatMobile";
import * as SecureStore from "expo-secure-store";


const choiceBoat = ( { isSelectedBoat, setIsSelectedBoat, setBoatName, setBoatCapacity, nameEvent, setNameEvent } ) => {

    const [token, setToken] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        if(Platform.OS === 'web'){
            const getToken = () => {
                const cookies = new Cookies;
                setToken(cookies.get('jwtToken'))
            }
            getToken()
        }else{
            const getValue = async () => { // Récupérer le token stocké dans le secureStore
                try {
                    setToken(await SecureStore.getItemAsync('jwtToken'));
                } catch (error) {
                    console.log('Erreur lors de la récupération de la valeur:', error);
                }
            };
            getValue();
        }
        const getUser = () => {
        if(token){
            api.get(`boats`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((res) => {
                setData(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
        }
        }
        getUser()
    }, [token])

    return (
        Platform.OS === 'web' ?
            <ChoiceBoatWeb
                data={data}
                setBoatName={setBoatName}
                setBoatCapacity={setBoatCapacity}
                setIsSelectedBoat={setIsSelectedBoat}
                isSelectedBoat={isSelectedBoat}
                nameEvent={nameEvent}
                setNameEvent={setNameEvent}
            />
            :
            <ChoiceBoatMobile
                data={data}
                setBoatName={setBoatName}
                setBoatCapacity={setBoatCapacity}
                setIsSelectedBoat={setIsSelectedBoat}
                isSelectedBoat={isSelectedBoat}
                nameEvent={nameEvent}
                setNameEvent={setNameEvent}
            />
    )
}

export default choiceBoat;
