import * as React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  Pressable,
  Image,
  ScrollView,
  Dimensions,
  Modal,
  SafeAreaView, Alert,
} from "react-native";
import Header from './Header';
import ModalConfirmMail from './ModalConfirmMail';
import { useState } from "react";
import {api} from "../../../config/axios";
function RegisterMobile( { navigation } ) {
  const [modal, setModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [lnErrorMessage, setLnErrorMessage] = useState('');
  const [fnErrorMessage, setFnErrorMessage] = useState('');
  const [mailErrorMessage, setMailErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [show, setShow] = useState(true)


  const nomRegex = /^[a-zA-Z\s]{3,}$/; // Au moins 3 lettres alphabétiques ou espaces
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Adresse email valide
  const telephoneRegex = /^0[1-9](\d{2}){4}$/; // Numéro de téléphone valide
  const passwordRegex = /[A-Za-z\d!@#$%^&*]{8,}/

  const heightVh = '100vh';


    const handleChange = (newState) => {
      setModal(newState)
    }

    function handlePress(e) {

      !nomRegex.test(firstName) ? setFnErrorMessage('message') : setFnErrorMessage('');
      !nomRegex.test(lastName) ? setLnErrorMessage('message') : setLnErrorMessage('');
      !emailRegex.test(mail) ? setMailErrorMessage('message') : setMailErrorMessage('');
      !telephoneRegex.test(phoneNumber) ? setPhoneErrorMessage('message') : setPhoneErrorMessage('');
      !passwordRegex.test(password) ? setPasswordErrorMessage('message') : setPasswordErrorMessage('');

      if(nomRegex.test(firstName) &&  emailRegex.test(mail) && telephoneRegex.test(phoneNumber) && passwordRegex.test(password)){
        e.preventDefault()

        let userData = {
          email: mail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          password: password,
          pinCode: "H7}\"",
          isPro: true
        }
        api.post(`auth/register`, userData).then((res) => console.log(res));
        if(handlePress){
          setModal(true)
        }
      }
    }

    return (
      <SafeAreaView
        style={{
          backgroundColor: '#EDEDED',
          width: '100%',
          minHeight: '100%',
          alignItems: 'center',
          flex: 1
        }}>

        <Header />

        <ScrollView contentContainerStyle={{width: Dimensions.get("window").width, alignItems: 'center'}}>
          <View style={styles.formContainer}>
            <Text style={styles.title}>M'inscrire</Text>
            <View>
              <Text style={styles.label}>Prénom</Text>
              {
                fnErrorMessage ? (
                  <>
                    <TextInput style={[styles.input, {borderColor: 'red'}]} onChangeText={(val) => setFirstName(val)} />
                    <Text style={styles.errorMessage}>Prénom invalide</Text>
                  </>
                ) : (
                <TextInput style={styles.input} onChangeText={(val) => setFirstName(val)} />
                )
              }


            </View>

            <View>
              <Text style={styles.label}>Nom</Text>
              {
                lnErrorMessage ? (
                  <>
                    <TextInput style={[styles.input, {borderColor: 'red'}]} onChangeText={(val) => setLastName(val)} />
                    <Text style={styles.errorMessage}>Nom invalide</Text>
                  </>
                ) : (
                  <TextInput style={styles.input} onChangeText={(val) => setLastName(val)} />
                )
              }
            </View>

            <View>
              <Text style={styles.label}>Email</Text>
              {
                mailErrorMessage ? (
                  <>
                    <TextInput style={[styles.input, {borderColor: 'red'}]} onChangeText={(val) => setMail(val)} />
                    <Text style={styles.errorMessage}>Mail invalide</Text>
                  </>
                ) : (
                  <TextInput style={styles.input} onChangeText={(val) => setMail(val)} />
                )
              }
            </View>

            <View>
              <Text style={styles.label}>Numéro de téléphone</Text>
              {
                phoneErrorMessage ? (
                  <>
                    <TextInput style={[styles.input, {borderColor: 'red'}]} onChangeText={(val) => setPhoneNumber(val)} />
                    <Text style={styles.errorMessage}>Numéro de téléphone invalide</Text>
                  </>
                ) : (
                  <TextInput style={styles.input} onChangeText={(val) => setPhoneNumber(val)} />
                )
              }
            </View>

            <View>
              <Text style={styles.label}>Password</Text>
              {
                passwordErrorMessage ? (
                    <>
                      <TextInput style={[styles.input, {borderColor: 'red'}]} secureTextEntry={show} autoCorrect={false}  onChangeText={(val) => setPassword(val)} placeholder='Doit contenir 6 caractères min.' />
                      <Pressable onPress={() => setShow(!show)}>
                        <Image style={styles.hideButton} source={require('../../../../assets/images/Show-Hide.png')}/>
                      </Pressable>
                      <Text style={styles.errorMessage}>Nécessite 8 caractères minimum, 1 lettre majuscule, 1 chiffre et 1 caractère sécial</Text>
                    </>
                ) : (
                    <>
                      <TextInput style={styles.input} secureTextEntry={show} autoCorrect={false} onChangeText={(val) => setPassword(val)}  placeholder='Doit contenir 6 caractères min.' />
                      <Pressable onPress={() => setShow(!show)}>
                        <Image style={styles.hideButton} source={require('../../../../assets/images/Show-Hide.png')}/>
                      </Pressable>
                    </>
                )
              }
            </View>

            <View style={{ marginTop: 20, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', marginLeft: 15, fontSize: 10 }}>
                Je souscris à la newsletter de Opiom
              </Text>
            </View>

            <View style={styles.button}>
              <Pressable style={({ pressed }) => [
                {
                  backgroundColor: pressed ? '#63A645' : '#D9D9DC',
                },
              ]} onPress={(e) => handlePress(e)}
              >
                <Text style={{ padding: 15, fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Je m'inscris</Text>
              </Pressable>
            </View>
            <View>
                <Text style={{textAlign: 'center', marginTop: 30, fontWeight: 'bold', textDecorationLine: 'none'}}>Vous possédez déjà un compte ?</Text>
            </View>
            <Pressable onPress={() => navigation.navigate('Login')}>
              <Text style={{textAlign: 'center', textDecorationLine: 'underline'}}>Connectez-vous ici !</Text>
            </Pressable>
            <View>
              <Text style={{ textAlign: 'center', marginVertical: 30 }}>
                En continuant, vous acceptez les {'\n'}
                <Text style={{ fontWeight: 'bold' }}>termes et conditions </Text>
                de Opiom
              </Text>
            </View>
            {modal && <ModalConfirmMail onChange={handleChange} />}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }


const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 50,
    textTransform: 'uppercase',
  },
  label: {
    margin: 10,
    marginLeft: 0,
  },
  buttonLogin: {
    color: 'black',
    fontFamily: 'montserrat',
  },
  button: {
    marginTop: 20,
    backgroundColor: '#D9D9DC',
    borderRadius: 4,
    color: 'white',
  },
  formContainer: {
    flex: 1,
    width: '80%',
    marginTop: 50
  },
  container: {
    padding: 8,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  input: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#BFBFC8',
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
  },
  login: {
    textAlign: 'center',
    marginTop: 50,
    fontSize: 30,
    fontWeight: 'bold',
    color: 'white',
  },
  hideButton: {
    position: 'absolute',
    right: 10,
    bottom: 15
  },
  errorMessage: {
    fontSize: 12,
    color: 'red',
    paddingVertical: 5,
    paddingLeft: 10,
  }
});

export default RegisterMobile;
