import {Text, StyleSheet, View, Pressable, TextInput, Image, Dimensions, ScrollView} from "react-native";
import React, { useState } from "react";
import {COLORS} from "../../../constants/colors";


const ChoiceBoatWeb = ( { data, isSelectedBoat, setBoatName, setBoatCapacity, setIsSelectedBoat, nameEvent, setNameEvent } ) => {
    const [selectId, setSelectId] = useState(null)
    const [changeName, setChangeName] = useState(false)

    const handleEvent = (id, name, capacity) => {
        if(isSelectedBoat === id) {
            setIsSelectedBoat(null)
            setSelectId(null)
            setBoatName(null)
            setBoatCapacity(null)
        }else{
            setIsSelectedBoat(id)
            setBoatName(name)
            setBoatCapacity(capacity)
        }
    }

    if(Dimensions.get('window').width > 650){
        return (
            <View style={styles.container}>
                <View style={{marginTop: 40, width: '50%'}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {
                            changeName ?
                                <TextInput style={[styles.h1, {borderWidth: 1, borderStyle: 'solid', borderColor: 'black'}]} placeholder={nameEvent} onChangeText={(newValue)=> setNameEvent(newValue)}/>
                                :
                                <Text style={styles.h1}>{nameEvent}</Text>
                        }
                        <Pressable style={[styles.suivi, {backgroundColor: 'white',shadowColor: '#000', shadowOpacity: 0.5, shadowOffset: { width: 0, height: 2,}, shadowRadius: 4, elevation: 2,
                        }]} onPress={() => setChangeName(!changeName)}>
                            <Text style={{opacity: 0.3}}>🖊️</Text>
                        </Pressable>
                    </View>
                </View>

                <View style={{marginTop: 20}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/boat2x.png')} />
                        <Text style={{marginLeft: 20, fontSize: 16}}>Choisissez votre bateau</Text>
                    </View>
                </View>

                <View style={{flexDirection: 'row', marginTop: 20, flexWrap: 'wrap', alignItems: 'center'}}>
                    {
                        data?.map((item) => (
                            <Pressable key={item.id} style={[styles.card, isSelectedBoat === item.id && {backgroundColor: '#0D1B2B'}]} onPress={() => handleEvent(item.id, item.name, item.capacity)}>
                                <View style={styles.imageBateau} />
                                <View style={{width: '80%', alignSelf: 'center'}}>
                                    <Text style={[{marginTop: 5}, isSelectedBoat === item.id && {color: 'white'}]}>{item.name}</Text>
                                    <Text style={[{marginTop: 5, marginBottom: 20}, isSelectedBoat === item.id && {color: 'white'}]}>{`Capacité : ${item.capacity}`}</Text>
                                </View>
                            </Pressable>
                        ))
                    }
                </View>
            </View>
        )
    }else {
        return (
            <View style={{width: '100%', alignItems: 'center'}}>
                <View style={{marginTop: 20}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/boat2x.png')} />
                        <Text style={{marginLeft: 20, fontSize: 20}}>Choisissez votre bateau</Text>
                    </View>
                </View>
                <View style={{alignItems: 'center', height: 500, width: '80%'}}>
                    <ScrollView  contentContainerStyle={{alignItems: 'center'}}>
                        {
                            data?.map((item) => (
                                // <Pressable key={item.id} style={[styles.card, isSelectedBoat === item.id && {backgroundColor: '#0D1B2B'}]} onPress={() => handleEvent(item.id, item.name)}>
                                //     <View style={styles.imageBateau} />
                                //     <View style={{width: '80%', alignSelf: 'center'}}>
                                //         <Text style={[{marginTop: 5}, isSelectedBoat === item.id && {color: 'white'}]}>{item.name}</Text>
                                //         <Text style={[{marginTop: 5, marginBottom: 20}, isSelectedBoat === item.id && {color: 'white'}]}>{`Capacité : ${item.capacity}`}</Text>
                                //     </View>
                                // </Pressable>
                                <Pressable key={item.id} style={isSelectedBoat === item.id ? styles.selectedCardMobile : styles.notSelectedCardMobile} onPress={() => handleEvent(item.id, item.name)}>
                                    <View style={styles.imageBateau}>
                                        {/*<Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />*/}
                                    </View>
                                    <View style={{width: '80%', alignSelf: 'center'}}>
                                        <Text style={[{marginTop: 5, marginBottom: 5, color: isSelectedBoat === item.id ? 'white' : COLORS.opiomMainColor}]}>{item.name}</Text>
                                        <Text style={[{marginTop: 5, marginBottom: 5, color: isSelectedBoat === item.id ? 'white' : COLORS.opiomMainColor}]}>{`Capacité : ${item.capacity}`}</Text>
                                    </View>
                                </Pressable>
                            ))
                        }
                    </ScrollView>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        width: '80%',
        height: 200,
        alignSelf: 'center',
    },
    h1: {
        textTransform: 'uppercase',
        fontSize: 20,
        opacity: 0.3,
        marginVertical: 5,
        marginRight: 10
    },
    card: {
        width: 150,
        marginRight: 30,
        borderRadius: 10
    },
    imageBateau: {
        backgroundColor: COLORS.gris,
        width: '80%',
        height: 100,
        borderRadius: 10,
        alignSelf: 'center',
        marginVertical: 15
    },
    notSelectedCardMobile: {
        backgroundColor: 'white',
        // height: 175,
        width: 150,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginHorizontal: 30,
        justifyContent: 'flex-end',
        // marginBottom: 30,
        marginTop: 30,
        alignItems: 'center',
    },
    selectedCardMobile: {
        width: 150,
        // marginRight: 30,
        // marginBottom: 30,
        marginTop: 30,
        borderRadius: 10,
        backgroundColor: COLORS.opiomMainColor
    },
})

export default ChoiceBoatWeb;
