import * as React from 'react';
import { Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import {COLORS} from "../../../../constants/colors";
import {api} from "../../../../config/axios";


function ModalInvitePerson( { data, setIsOpenModalInvite, token } ) {
    const [showModal, setShowModal] = useState(true)
    const [link, setLink] = useState('')
    console.log(link)
    const handleSubmit = () => {
        if(link){
            api.post(`events/${data.id}`, null, {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                params: {
                    link
                }
            })
                .then(res => console.log('foncyionne ' + res))
                .catch((error) => console.log(error))
        }
    }
    console.log(`events/${data.id}?link=${link}`)

    return (
        <Modal
            transparent={true}
            visible={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
        >
            <View style={styles.modalContainer}></View>
            <View style={styles.modalContent}>
                <Text style={{marginTop: 40, fontSize: 16}}>Veuillez insérer le lien google sheet contenant la liste des personnes à inviter dans le champ ci-dessous</Text>
                <TextInput style={styles.input} onChangeText={(newVal) => setLink(newVal)} />
                <Text style={{fontSize: 16, marginBottom: 10}}>
                    Veuillez suivre les instructions notées sur le document : <a href='https://docs.google.com/spreadsheets/d/1JUYmesq06ptnVdzI-LmZFpCYiNppAAySQZyKTW7pIY4/edit#gid=0' target='_blank'>Ici</a>
                </Text>
                <Text style={{fontSize: 16}}>
                    Avant de valider, veillez à ce que les noms, prénoms et adresses mails sont bien notés dans leur colonne respective.{'\n'}
                    Si cela n'est pas respecté, les personnes concernées risqueront de ne pas recevoir d'invitation.
                </Text>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Pressable style={styles.editButton} onPress={() => setIsOpenModalInvite(false)}>
                        <Text style={{color: 'white', fontSize: 15}}>Fermer</Text>
                    </Pressable>
                    <Pressable style={styles.editButton} onPress={handleSubmit}>
                        <Text style={{color: 'white', fontSize: 15}}>Valider et envoyer</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalContent: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        width: '80%',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 2,
        paddingTop: 20,
        position: 'absolute',
        top: 200,
        left: '10%'
    },
    modalContainer: {
        backgroundColor: '#002759',
        opacity: 0.5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    editButton: {
        marginTop: 40,
        marginBottom: 70,
        marginHorizontal: 10,
        paddingVertical: 15,
        width: 200,
        backgroundColor: COLORS.opiomMainColor,
        alignItems: 'center'
    },
    input: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#0F1C2B',
        marginTop: 20,
        marginBottom: 30,
        width: 500,
        height: 25
    },
    button: {
        width: 160,
        height: 50,
        backgroundColor: '#0F1C2B',
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default ModalInvitePerson;
