import * as React from 'react';
import { Modal, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import {COLORS} from "../../../../constants/colors";


function ModalLastEventsDetailsWeb( { data, setIsOpenModal } ) {
    const [showModal, setShowModal] = useState(true)
    const [event, setEvent] = useState([
        'Bateau choisi', 'Moment choisi', 'Date choisie', 'Invités', 'Prestations Food', 'Prestations Animations', 'Prestations Bar', 'Demandes Particulières'
    ])


    return (
        <Modal
            transparent={true}
            visible={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
        >
            <View style={styles.modalContainer}></View>
            <View style={styles.modalContent}>
                <Text style={{fontSize: 18, color: '#002759', paddingTop: 15}}>Afficher toutes les icones</Text>
                <View style={styles.formContainer}>
                    <View>
                        <Text style={{fontSize: 40, color: '#002759', opacity: 0.2}}>{data.name}</Text>
                        {/*<Text style={{fontSize: 26, color: '#002759', opacity: 0.2}}>{data.organizer.name}</Text>*/}
                    </View>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center', width: '100%', marginTop: 50}}>
                        {
                            event.map((item, key) => (
                                <View key={key} style={styles.card}>
                                    <Text style={{color: '#333333', fontSize: 15}}>{item}</Text>
                                    <View style={{backgroundColor: COLORS.gris, marginTop: 20, width: 200}}>
                                        {/*{*/}
                                        {/*    key === 0 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.boat.name}</Text> :*/}
                                        {/*    key === 1 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.horaires}</Text> :*/}
                                        {/*    key === 2 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.startDate}</Text> :*/}
                                        {/*    key === 3 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.nombresPersonnes}</Text> :*/}
                                        {/*    key === 4 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.PrestationsFood}</Text> :*/}
                                        {/*    key === 5 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.prestationsAnimations}</Text> :*/}
                                        {/*    key === 6 ?*/}
                                        {/*    <Text style={styles.textInfos}>{data.PrestationsBar}</Text> :*/}
                                        {/*    key === 7 &&*/}
                                        {/*    <Text style={styles.textInfos}>{data.specialRequest}</Text>*/}
                                        {/*}*/}
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </View>
                <Pressable style={styles.editButton} onPress={() => setIsOpenModal(false)}>
                    <Text style={{fontSize: 18, color: 'white', fontWeight: 'bold'}}>Fermer</Text>
                </Pressable>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalContent: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        width: '80%',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 2,
        paddingTop: 20,
        position: 'absolute',
        top: 200,
        left: '10%'
    },
    modalContainer: {
        backgroundColor: '#002759',
        opacity: 0.5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    formContainer: {
        flex: 1,
        width: '85%',
        marginTop: 30
    },
    card: {
        width: '25%',
        alignItems: 'center',
        marginVertical: 20
    },
    editButton: {
        marginTop: 40,
        marginBottom: 70,
        paddingVertical: 15,
        width: 200,
        backgroundColor: COLORS.opiomMainColor,
        alignItems: 'center'
    },
    textInfos: {
        textAlign: 'center',
        paddingVertical: 10,
        color: '#333333',
        fontSize: 15,
        fontWeight: '200'
    }
})

export default ModalLastEventsDetailsWeb;
