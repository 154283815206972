import {StyleSheet} from "react-native";

export const profilStyle = StyleSheet.create({
    BGColorOpiom: {
        backgroundColor: '#002759'
    },
    textColorOpiom: {
        color: '#002759'
    },
    
})
