import React, {useState} from 'react';
import {Image, StyleSheet, Text, View, Platform, Dimensions, ScrollView} from "react-native";
import {Pressable} from "react-native-web";
import {COLORS} from "../../../constants/colors";
import AddOrgModal from "./AddOrgModal";

function ChoiceOrgWeb( { setOrgId, data, idUser, token, setIsPublic, isEventSelected, setIsEventSelected }  ) {

    const [isSelected, setIsSelected] = useState(null)
    const [openModal, setOpenModal] = useState(false)


    const handlePress = (id) => {
        if(isSelected === id) {
            setIsSelected(null)
            setIsEventSelected(null)
        }else{
            setIsSelected(id)
            id !== 0 ? setOrgId(id) : setIsPublic(false)
        }
    };

    const handleEvent = (id) => {
        setIsEventSelected(id)
        if(isEventSelected === id) {
            setIsEventSelected(null)
        }else {
            setIsEventSelected(id)
            id === 1 ? setIsPublic(true) : setIsPublic(false)
        }
    }

    if(Dimensions.get('window').width > 650){
        return(
            Platform.OS !== 'mobile' &&
                <View style={styles.container}>
                    <Text style={styles.title}>À quel nom organisez-vous l'évènement</Text>
                    <View style={styles.orgContainer}>
                        <View style={{flexDirection: 'column'}}>
                            <Pressable style={[styles.card, {borderColor: COLORS.gris, backgroundColor: COLORS.gris}]} onPress={() => handlePress(0)}>
                                <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                            </Pressable>
                            <Text style={[styles.textEvent, {marginTop: 20, marginLeft: 40}]}>Moi-même</Text>
                        </View>
                        {
                            data?.map((item) => (
                                isSelected === item.id ?
                                <View key={item.id} style={{flexDirection: 'column'}}>
                                    <Pressable style={styles.selectedCard} onPress={() => handlePress(item.id)}>
                                        <Text style={[styles.textEvent, {marginBottom: 5, textAlign: 'left'}]}>{item.name}</Text>
                                    </Pressable>
                                </View>
                                    :
                                <View key={item.id} style={{flexDirection: 'column'}}>
                                    <Pressable style={[styles.card, {borderColor: COLORS.gris}]} onPress={() => handlePress(item.id)}>
                                        <Text>{item.name}</Text>
                                    </Pressable>
                                    <Text style={[styles.textEvent, {marginTop: 20, marginLeft: 40}]}>{item.name}</Text>
                                </View>
                            ))
                        }
                        <Pressable style={[styles.card, {backgroundColor: COLORS.gris, borderColor: COLORS.gris}]} onPress={() => setOpenModal(true)}>
                            <Text style={{textAlign: 'center', margin: 5}}>Ajouter une organisation</Text>
                        </Pressable>
                    </View>
                    {openModal && <AddOrgModal setOpenModal={setOpenModal} idUser={idUser} token={token} />}
                        {
                            isSelected || isSelected === 0 ? (
                                <>
                                    <View style={styles.barreHoriz}/>
                                    <View style={{width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                                        <View style={{flexDirection: 'column'}}>
                                            <Pressable style={[styles.card, {marginRight: 0, marginLeft: 20, borderColor: isEventSelected === 1 ? COLORS.opiomMainColor : COLORS.gris}]} onPress={() => handleEvent(1)}>
                                                <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                                            </Pressable>
                                            <Text style={[styles.textEvent, {marginTop: 20, marginLeft: 20}]}>Évènement public</Text>
                                        </View>
                                        <View style={{flexDirection: 'column'}}>
                                            <Pressable style={[styles.card, {marginRight: 0, marginLeft: 20, borderColor: isEventSelected === 2 ? COLORS.opiomMainColor : COLORS.gris}]} onPress={() => handleEvent(2)}>
                                                <Image style={{height: 60, width: 60}} resizeMode='contain' source={require('../../../../assets/images/private-event2x.png')} />
                                            </Pressable>
                                            <Text style={[styles.textEvent, {marginTop: 20, marginLeft: 20}]}>Évènement privé</Text>
                                        </View>
                                    </View>
                                    <View>

                                    </View>
                                </>
                            ) : (
                                <></>
                            )
                        }
                </View>
        )
    }else {
        return(
            <View style={{width: '100%', alignSelf: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: 20, textAlign: 'center'}}>À quel nom organisez-vous l'évènement</Text>
                <View style={{alignItems: 'center', height: 500, width: '80%'}}>
                    <ScrollView contentContainerStyle={{alignItems: 'center'}}>
                        <Pressable style={isSelected === 0 ? styles.selectedCardMobile : styles.notSelectedCardMobile} onPress={() => handlePress(0)}>
                            <View style={styles.imageBateau}>
                                <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                            </View>
                            <View style={{width: '80%', alignSelf: 'center'}}>
                                <Text style={[{marginTop: 5, marginBottom: 5, color: isSelected === 0 ? 'white' : COLORS.opiomMainColor}]}>Moi même</Text>
                            </View>
                        </Pressable>
                        {
                            data?.map((item) => (
                                <Pressable key={item.id} style={isSelected === item.id ? styles.selectedCardMobile : styles.notSelectedCardMobile} onPress={() => handlePress(item.id)}>
                                    <View style={styles.imageBateau}>
                                        <Image style={{height: 40, width: 40}} resizeMode='contain' source={require('../../../../assets/images/people-fill2x.png')} />
                                    </View>
                                    <View style={{width: '80%', alignSelf: 'center'}}>
                                        <Text style={[{marginTop: 5, marginBottom: 5, color: isSelected === item.id ? 'white' : COLORS.opiomMainColor}]}>{item.name}</Text>
                                    </View>
                                </Pressable>
                            ))
                        }
                        <Pressable style={[styles.card, {backgroundColor: COLORS.gris, borderColor: COLORS.gris}]} onPress={() => setOpenModal(true)} >
                            <Text style={{textAlign: 'center', margin: 5}}>Ajouter une organisation</Text>
                        </Pressable>
                        {openModal && <AddOrgModal setOpenModal={setOpenModal} idUser={idUser} token={token} />}
                    </ScrollView>
                    <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-around', marginTop: 20}}>
                        <Pressable style={isEventSelected === 1 ? styles.buttonSelected : styles.buttonNotSelected} onPress={() => handleEvent(1)}>
                            <Text style={isEventSelected === 1 ? styles.textButtonSelected : styles.textButtonNotSelected}>Public</Text>
                        </Pressable>
                        <Pressable style={isEventSelected === 2 ? styles.buttonSelected : styles.buttonNotSelected} onPress={() => handleEvent(2)}>
                            <Text style={isEventSelected === 2 ? styles.textButtonSelected : styles.textButtonNotSelected}>Privé</Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        width: '80%',
        alignSelf: 'center',
    },
    title: {
        fontSize: 30,
        marginVertical: 20,
        marginLeft: 10
    },
    orgContainer: {
        alignSelf: 'center',
        marginTop: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    cardContainer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center'
    },
    card: {
        height: 130,
        width: 130,
        borderStyle: 'solid',
        borderWidth: 10,
        borderRadius: 10,
        marginHorizontal: 30,
        marginTop: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectedCard: {
        backgroundColor: 'white',
        height: 175,
        width: 130,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginHorizontal: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    barreHoriz: {
        width: 400,
        height: 1,
        backgroundColor: '#737A83',
        marginVertical: 50,
        alignSelf: 'center'
    },
    textEvent: {
        fontSize: 13,
        color: '#11212F'
    },
    imageBateau: {
        backgroundColor: COLORS.gris,
        width: '80%',
        height: 90,
        borderRadius: 10,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 15
    },
    buttonSelected: {
        width: 150,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderBottomColor: COLORS.opiomMainColor,
        backgroundColor: COLORS.opiomMainColor,
        borderRadius: 5
    },
    buttonNotSelected: {
        width: 150,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderBottomColor: COLORS.opiomMainColor,
        borderRadius: 5
    },
    textButtonSelected: {
        fontWeight: "600",
        color: 'white'
    },
    textButtonNotSelected: {
        fontWeight: "600",
    },
    notSelectedCardMobile: {
        backgroundColor: 'white',
        // height: 175,
        width: 150,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginHorizontal: 30,
        justifyContent: 'flex-end',
        // marginBottom: 30,
        marginTop: 30,
        alignItems: 'center',
    },
    selectedCardMobile: {
        width: 150,
        // marginRight: 30,
        // marginBottom: 30,
        marginTop: 30,
        borderRadius: 10,
        backgroundColor: COLORS.opiomMainColor
    },
})

export default ChoiceOrgWeb
