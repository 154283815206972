import {Iframe} from "@bounceapp/iframe";
import {View} from "react-native";
import HeaderScreen from "../HeaderScreen";

const CreditAccountMobile = () => {
    return(
        <View style={{flex: 1}}>
            <HeaderScreen />
            <Iframe uri="https://monespace.wiloutcashless.com/8c0fb78b7428313290f82a3faa2aa4b0/w" />
        </View>
    )
}

export default CreditAccountMobile