import {Text, StyleSheet, View, Pressable, TextInput, Image, Dimensions, ScrollView} from "react-native";
import {useEffect, useState} from "react";
import Calendars from "./Calendars";
import Slider from '@react-native-community/slider'
import {COLORS} from "../../../constants/colors";

const ChoiceHoraireWeb = ( { data, setHoraire, setSelectDay, isSelectedTime, setIsSelectedTime, selectDay, setDuration } ) => {
    const [selectId, setSelectId] = useState(null)

    const handleEvent = (id, horaire, duration) => {
        if(isSelectedTime === id) {
            setIsSelectedTime(null)
            setSelectId(null)
            setHoraire(null)
        }else{
            setIsSelectedTime(id)
            setSelectId(id)
            setHoraire(horaire.slice(0, horaire.indexOf("H")))
            setDuration(duration)
        }
    }

    if(Dimensions.get('window').width > 650){
        return (
            <View style={styles.container}>
                <View>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/clock-fill2x.png')} />
                        <Text style={{marginLeft: 20, fontSize: 16}}>Choisissez votre moment de la journée</Text>
                    </View>
                </View>

                <View style={styles.content}>
                        <Pressable style={[styles.card, selectId == 1 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(1, '6H - 11H', 5)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 1 && {color: 'white'}]}>Matin</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 1  && {color: 'white'}]}>6H - 11H</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 2 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(2, '12H - 17H', 5)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 2 && {color: 'white'}]}>Midi</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 2  && {color: 'white'}]}>12H - 17H</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 3 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(3, '18H - 2H', 8)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 3 && {color: 'white'}]}>Soir</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 3  && {color: 'white'}]}>18H - 2h</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 4 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(4, 'Journée complète', 'Journée complète')}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 4 && {color: 'white'}]}>Journée</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 4  && {color: 'white'}]}>Complète</Text>
                                </View>
                            </View>
                        </Pressable>
                </View>
                <View style={{height: 1, width: '90%', backgroundColor: COLORS.gris}}/>
                    {
                        !isSelectedTime ?
                        <>
                            <Pressable style={[styles.card, selectId == 5 && {backgroundColor: '#0B1B2B'}, {marginLeft: 40}]} onPress={() => setSelectId(5)}>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                    <View style={{flexDirection: 'column', marginRight: 10}}>
                                        <Text style={[{paddingBottom: 3}, selectId == 5 && {color: 'white'}]}>Personnalisé</Text>
                                    </View>
                                </View>
                            </Pressable>
                            {
                                selectId === 5 &&
                                <Slider style={{width: 300, height: 50}} />
                            }
                        </>
                            :
                            <View>
                                <View style={{marginTop: 50, marginLeft: -35}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/calendar-date-fill2x.png')} />
                                        <Text style={{marginLeft: 20, fontSize: 16}}>Choisissez la date</Text>
                                    </View>
                                    <View style={{marginTop: 20}}>
                                        <Calendars selectDay={selectDay} setSelectDay={setSelectDay}/>
                                    </View>
                                </View>
                            </View>
                    }
            </View>
        )
    }else {
        return(
            <View style={styles.containerMobile}>
                <View style={{marginTop: 50}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/clock-fill2x.png')} />
                        <Text style={{marginLeft: 20, fontSize: 16}}>Choisissez votre moment de la journée</Text>
                    </View>
                </View>

                <View style={{height: 120, width: '95%'}}>
                    <ScrollView horizontal={true}>
                        <Pressable style={[styles.card, selectId == 1 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(1, '6H - 11H', 5)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 1 && {color: 'white'}]}>Matin</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 1  && {color: 'white'}]}>6H - 11H</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 2 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(2, '12H - 17H', 5)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 2 && {color: 'white'}]}>Midi</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 2  && {color: 'white'}]}>12H - 17H</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 3 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(3, '18H - 2H', 8)}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 3 && {color: 'white'}]}>Soir</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 3  && {color: 'white'}]}>18H - 2h</Text>
                                </View>
                            </View>
                        </Pressable>
                        <Pressable style={[styles.card, selectId == 4 && {backgroundColor: '#0B1B2B'}]} onPress={() => handleEvent(4, 'Journée complète', 'Journée complète')}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                <View style={{flexDirection: 'column', marginRight: 10}}>
                                    <Text style={[{paddingBottom: 3}, selectId == 4 && {color: 'white'}]}>Journée</Text>
                                    <Text style={[{paddingBottom: 3}, selectId == 4  && {color: 'white'}]}>Complète</Text>
                                </View>
                            </View>
                        </Pressable>
                        {
                            !isSelectedTime &&
                            <>
                                <Pressable style={[styles.card, selectId == 5 && {backgroundColor: '#0B1B2B'}]} onPress={() => setSelectId(5)}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <View style={{height: 15, width: 15, borderRadius: 5, backgroundColor: 'white', marginRight: 20}} />
                                        <View style={{flexDirection: 'column', marginRight: 10}}>
                                            <Text style={[{paddingBottom: 3}, selectId == 5 && {color: 'white'}]}>Personnalisé</Text>
                                        </View>
                                    </View>
                                </Pressable>
                                {
                                    selectId === 5 &&
                                    <Slider style={{width: 300, height: 50}} />
                                }
                            </>
                        }
                    </ScrollView>
                    {
                        isSelectedTime &&
                        <View style={{alignItems: 'center'}}>
                            <View style={{position: 'absolute', top: '100%'}}>
                                <View style={{alignItems: 'center', flexDirection: 'row', marginBottom: 20}}>
                                    <Image style={{height: 25, width: 25}} source={require('../../../../assets/images/calendar-date-fill2x.png')} />
                                    <Text style={{fontSize: 16, textAlign: 'center'}}>Choisissez la date</Text>
                                </View>
                                <View style={{marginTop: 20}}>
                                    <Calendars selectDay={selectDay} setSelectDay={setSelectDay}/>
                                </View>
                            </View>
                        </View>
                    }
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    containerMobile: {
        width: '100%',
        height: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        // backgroundColor: 'blue'
    },
    container: {
        width: '80%',
        // height: 200,
        alignSelf: 'center',
    },
    content: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '90%',
        margin: 5,
        marginLeft: 40,
        alignItems: 'center',
        // backgroundColor: 'red'
    },
    h1: {
        textTransform: 'uppercase',
        fontSize: 20,
        opacity: 0.3,
        marginVertical: 5,
        marginRight: 10
    },
    card: {
        width: 150,
        marginRight: 30,
        borderRadius: 10,
        backgroundColor: '#E6E8E9',
        alignItems: 'center',
        paddingVertical: 15,
        marginTop: 20,
        marginBottom: 20,
    },
})

export default ChoiceHoraireWeb;
