import { Text, View, StyleSheet, Image, Pressable } from "react-native";
import {profilStyles} from '../../../../Generics Styles/ProfilStyles'
import {COLORS} from "../../../constants/colors";

function HeaderProfileWeb() {


    return (
        <>
            <View style={styles.topHeaderContainer}>
                <View style={styles.topHeaderContent}>
                    <Text style={styles.topHeaderText}>06 06 06 06 06</Text>
                    <Text style={styles.topHeaderText}>Réservez votre séjour </Text>
                    <Text style={styles.topHeaderText}>Contactez-nous</Text>
                </View>
            </View>
            <View style={styles.headerNav}>
                <View style={styles.navContainer}>
                    <Pressable>
                        <Text>LOGO</Text>
                    </Pressable>
                    <View  style={styles.navContent}>
                        <Pressable>
                            <Text style={styles.itemNav}>
                                Accueil
                            </Text>
                        </Pressable>
                        <Pressable>
                            <Text style={styles.itemNav}>
                                Nos prestations
                            </Text>
                        </Pressable>
                        <Pressable>
                            <Text style={styles.itemNav}>
                                Tarifs
                            </Text>
                        </Pressable>
                        <Pressable>
                            <Text style={styles.itemNav}>
                                Notre histoire
                            </Text>
                        </Pressable>
                        <Pressable>
                            <Text style={[styles.itemNav, {fontWeight: 'bold'}]}>
                                Thomas
                            </Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    topHeaderContainer: {
        width: '100%',
        height: 50,
        backgroundColor: '#002759',
        alignItems: 'center'
    },
    topHeaderContent: {
        width: '50%',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginRight: 70
    },
    topHeaderText: {
        fontSize: 18,
        color: 'white'
    },
    headerNav: {
        width: '100%',
        height: 150,
        backgroundColor: COLORS.gris,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: '#00000029',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 1,
        shadowRadius: 6,
        elevation: 20,
    },
    navContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '80%'
    },
    navContent: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: 800
    },
    itemNav: {
        color: '#002759',
        fontSize: 18
    }
})

export default HeaderProfileWeb;
