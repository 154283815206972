import * as React from 'react';
import { Image, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import Header from '../Components/Identification/Header';
import {COLORS} from "../../constants/colors";

function Identification({ navigation }) {
  return (
    <View
      style={{
        backgroundColor: '#EDEDED',
        width: '100%',
        minHeight: '100%',
        alignItems: 'center'
      }}>

      <View>
        <Header />
      </View>

      <View style={{marginTop: 54, width: '100%'}}>
        <Text style={styles.title}>
          Opiom
        </Text>

        <View style={{marginTop: 54, alignItems: 'center'}}>
          <Pressable style={styles.loginButton} onPress={() => navigation.navigate('Login')}>
              <Text style={styles.login}>
                Se connecter
              </Text>
          </Pressable>

          <Pressable style={styles.loginButton2} onPress={() => navigation.navigate('Register')}>
              <Text style={styles.register}>
                S'inscrire
              </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  loginButton: {
    marginTop: 54,
    backgroundColor: '#D9D9DC',
    borderStyle: 'solid',
    borderRadius: 4,
    width: '80%',
    alignItems: 'center',
    padding: 13
  },
  loginButton2: {
    marginTop: 54,
    backgroundColor: COLORS.opiomMainColor,
    borderStyle: 'solid',
    borderRadius: 4,
    width: '80%',
    alignItems: 'center',
    padding: 13
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    color: '#2A2A48',
    // fontFamily: 'Montserrat',
    fontWeight: '700',
    textTransform: 'uppercase'
  },
  login: {
    color: '#2A2A48',
    fontWeight: '400',
    fontSize: 16,
  },
  register: {
    color: '#FFFFFF',
    fontWeight: '400',
    fontSize: 16,
  }
});

export default Identification;
